.book-service-body-paint {
    max-width: 642px;
    width: 100%;
    height: fit-content;
    padding: 32px;
    border: 1px solid var(--background-stroke-color);
    grid-column: 2;
    grid-row: 1 / span 3;

    .widget {
        /* stylelint-disable-next-line */
        overflow-x: inherit !important;
    }

    @include tablet {
        padding: 24px;
    }

    @include mobile {
        padding: 24px;
    }

    &-ru {
        .Field {
            &-Label {
                font-weight: 700;
            }
        }
    }

    .FlexibleFormsWidget {
        margin-block-start: 0;
        margin-block-end: 0;

        .Form {
            display: flex;
            flex-direction: column;

            h2 {
                margin-block-start: 0;
                margin-block-end: 32px;

                @include mobile {
                    font-size: var(--h2-font-size);
                    text-align: start;
                    margin-block-end: 24px;
                }
            }

            p {
                display: none;
            }

            div {
                hr,
                h3 {
                    display: none;
                }
            }

            > div > div {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .Field {
                &-Wrapper {
                    margin-block-start: 0;
                    margin-block-end: 16px;

                    @include mobile {
                        margin-block-end: 24px;
                    }

                    > div {
                        margin-block-start: 0;
                    }

                    &_type {
                        &_text {
                            max-width: 273px;
                            width: 100%;

                            @include mobile {
                                max-width: 100%;
                            }

                            &:nth-of-type(3), &:nth-of-type(4) {
                                width: 100%;
                                max-width: none;
                            }
                        }

                        &_email {
                            width: 100%;
                        }

                        &_select,
                        &_textarea,
                        &_number {
                            width: 100%;
                        }

                        &_checkbox {
                            /* stylelint-disable-next-line */
                            margin: 0 0 14px 0 !important;

                            > div {
                                margin-block-start: 0;
                            }
                        }

                        &_checkbox:last-of-type {
                            /* stylelint-disable-next-line */
                            margin-block-end: 0 !important;
                        }
                    }

                    input,
                    textarea {
                        width: 100%;
                    }

                    textarea {
                        height: 104px;
                    }

                    ul {
                        /* stylelint-disable-next-line */
                        margin-inline-start: 0 !important;
                    }

                    .FlexibleForms {
                        &-required {
                            label::after {
                                content: " *";
                                color: #FF3939;
                            }
                        }
                    }
                }
            }

            .FieldGroup {
                &-Wrapper {
                    margin-block: 0 16px;

                    .FieldGroup {
                        flex-direction: column;
                    }
                }
            }

            button {
                margin-block-start: 16px;
                align-self: flex-end;
                width: 152px;
                height: 48px;

                @include mobile {
                    width: 100%;
                }
            }
        }
    }
}
