/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
    --blog-category-filter-color: #{$black};
    --blog-category-filter-backround-active-color: #{$black};
    --blog-category-filter-backround-color: #{$white};
}

.BlogTags {
    &-Wrapper {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }

    &-Tag {
        display: flex;
        height: fit-content;
        text-align: center;
        margin-inline-end: 15px;
        margin-block-end: 15px;

        @include mobile {
            margin-inline-end: 0;
        }

        h3 {
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            margin: auto;
            padding: 4px 8px;
            background-color: var(--color-black);
            color: var(--color-white);
            overflow-x: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100px;
        }

        a {
            height: 32px;

            &:hover {
                h3 {
                    @include desktop {
                        color: var(--color-dark-blue);
                    }
                }
            }
        }

        &_isSelected {
            h3 {
                color: var(--color-dark-blue);
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 4px;
            }

            a {
                &:hover {
                    h3 {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    &-Title {
        text-align: left;
        display: inline-block;
        text-transform: capitalize;

        @include mobile {
            margin-bottom: 5px;
        }

        @include desktop {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 1rem;
            margin-top: 20px;
        }
    }

    &-Tags {
        @include mobile {
            grid-column-gap: 15px;
            text-align: left;
            display: flex;
            flex-wrap: wrap;
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
