:root {
    --link-color: var(--imported_link_color, var(--body-content-color));
    --link-hover-color: var(--imported_linkHover_color, var(--primary-hover-color));
    --default-hover-link-underline: underline;
    --link-underline: var(--imported_link_border_bottom, var(--default-hover-link-underline));
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    border-block-end: none;
    color: var(--link-color);
    font-size: 18px;
    font-weight: 500;

    &:hover {
        color: var(--link-hover-color);
    }

    &.Link {
        text-decoration: var(--link-underline);

        &:hover,
        &:focus {
            border-block-end: none;
            color: var(--link-hover-color);
            padding-block-end: 0;
        }
    }
}
