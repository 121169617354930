.CmsPage {
    .leasing-service {
        max-width: 642px;
        width: 100%;
        padding: 32px;
        border: 1px solid var(--background-stroke-color);

        @include tablet {
            padding: 16px;
        }

        @include mobile {
            padding: 16px;
        }

        h1 {
            font-weight: 700;
            margin-block-end: 44px;

            @include mobile {
                margin-block-end: 32px;
            }
        }

        ul {
            /* stylelint-disable-next-line */
            margin-inline-start: 0 !important;
            margin-block-end: 44px;

            @include mobile {
                margin-block-end: 32px;
            }

            li {
                display: flex;
                align-items: center;
                margin-block-end: 28px;

                div {
                    margin-inline-end: 16px;
                    max-width: 40px;
                    width: 100%;
                    height: 40px;
                }

                .leasing-calculator-icon {
                    background: url(../../style/icons/LeasingPage/calculator-icon.svg) no-repeat;
                }

                .leasing-coins-icon {
                    background: url(../../style/icons/LeasingPage/coins-icon.svg) no-repeat;
                }

                .leasing-service-option-text {
                    /* stylelint-disable-next-line */
                    margin-block-end: 0 !important;
                    font-weight: 700;
                    font-size: var(--h2-font-size);

                    @include mobile {
                        font-size: var(--h2-font-size-mobile);
                    }
                }
            }
        }
    }
}
