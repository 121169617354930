/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../../src/style/abstract/variables';
@import '../../../../../../src/style/abstract/media';
@import '../../../../../../src/style/abstract/button';
@import '../../../../../../src/style/abstract/loader';
@import '../../../../../../src/style/abstract/icons';
@import '../../../../../../src/style/abstract/parts';
@import '../../../../../../src/style/abstract/variables.override';
@import '../../../../../../src/style/abstract/media.override';
@import '../../../../../../src/style/abstract/button.override';

.FieldMultiSelect {
    &-Select {
        /* stylelint-disable-next-line declaration-no-important */
        height: 130px !important;
    }

    &-Options {
        @include mobile {
            display: none;
        }
    }
}
