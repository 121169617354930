.SeoBlock {
    @include mobile {
        margin-inline: 16px;
    }

    &-Heading {
        margin-block-start: 0px;
        margin-block-end: 8px;
    }

    &-Content {
        color: var(--body-description-color);
    }
}
