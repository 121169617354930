/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRecentPosts {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-Wrapper {
        display: flex;
        gap: 32px;
        flex-basis: 60%;
        flex-direction: column;

        @include desktop {
            gap: 175px;
        }
    }

    &-PostCard {
        display: flex;
        flex-direction: column;
        gap: 24px;

        &:hover {
            color: inherit;
        }

        @include desktop {
            flex-direction: row;
            gap: 48px;

            &:nth-child(odd) {
                .BlogRecentPosts-ImageWrapper {
                    order: 2;
                }

                .BlogRecentPosts-ContentWrapper {
                    order: 1;
                }
            }
        }
    }

    &-ContentWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 60%;

        .Button {
            margin-block-start: 24px;
            align-self: flex-end;
        }
    }

    &-PostTitle {
        text-align: start;
        text-transform: none;
        margin: 16px 0;

        @include desktop {
            margin-block-start: 0;
        }
    }

    &-PostTitlePlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-Title {
        text-align: center;
    }

    &-ImageWrapper {
        flex-basis: 40%;

        .Image {
            &-Image {
                object-fit: cover;
            }
        }
    }

    &-PostFooter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &-PostDescription {
        color: var(--color-black);
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        div[data-content-type="row"][data-appearance="contained"] {
            padding: 0;
        }
    }
}
