/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

@import "../../style/abstract/functions";

.PrivacyConsentCheckboxes {
    margin-top: 12px;

    &-Consents {
        margin: 0 0 0 -7px;
    }

    &-Consent {
        padding: 0;
        display: flex;
        font-size: 12px;
        align-items: center;

        &,
        &:last-of-type {
            margin-bottom: 10px;
        }

        &::before {
            display: none;
        }
    }

    &-Box {
        margin: 0;
    }

    &-BoxLabel {
        font-size: 12px;
        margin-left: 10px;
    }

    &-RequiredMessage {
        color: var(--primary-error-color);
    }

    &-Wrapper {
        display: flex;
    }

    &-SignUp {
        &,
        &:last-of-type {
            margin-top: 10px;
            margin-bottom: 5px;
            font-size: 14px;

            @include mobile {
                text-align: center;
            }
        }
    }

    &-Tooltip {
        text-align: center;
        display: flex;
        height: 100%;
        top: 40px;
        margin-left: 19px;
        cursor: pointer;

        &:before {
            content: '?';
        }

        &:hover {
            &:before {
                content: '?';
            }
        }
    }

    .Tooltip {
        &-Content {
            text-align: start;
            right: 0;
        }
    }

    .Button[disabled] {
        background: blue;
        opacity: .5;
    }
}
