/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --gdpr-cookies-box-shadow-color: rgba(0, 0, 0, .3);
    --gdpr-cookies-button-padding: 5px 15px;
    --gdpr-cookies-group-scrollbar-thumb-color: #e8e8e8;
    --gdpr-cookies-group-scrollbar-track-color: #fff;
}

.GdprCookiesPopup {
    align-items: flex-start;
    justify-content: start;

    .Popup {
        &-Content {
            z-index: 2147483601;
            border-radius: 0;
            position: fixed;
            inset-block-end: 0;
            max-height: 100%;
            max-width: 400px;
            overflow-y: hidden;
            padding: 16px;
            height: 100%;
            box-shadow: 1px 20px 80px 1px rgba(0, 0, 0, 0.9);

            @include mobile {
                box-shadow: 0 0 12px 2px var(--gdpr-cookies-box-shadow-color);
                height: 100vh;
                position: unset;
                inset-block-end: unset;
                overflow-y: scroll;
            }
        }

        &-Header {
            display: none;
        }
    }

    &-Header {
        .Popup-CloseBtn {
            inset-block-start: 0;
            inset-inline-end: 0;
        }
    }

    &_isPopup {
        align-items: center;
        justify-content: center;

        .Popup {
            &-Content {
                padding: var(--popup-content-padding);
                min-width: var(--popup-min-width);
                max-width: calc(var(--content-wrapper-width) * .8);
                max-height: var(--popup-max-height);
                overflow-y: auto;
                inset-block-end: unset;
                height: 100vh;

                @include mobile {
                    box-shadow: 0 0 12px 2px var(--gdpr-cookies-box-shadow-color);
                    height: 100vh;
                    position: unset;
                    inset-block-end: unset;
                    overflow-y: scroll;
                    max-height: unset;
                }
            }
        }
    }

    &_isClassicBar {
        align-items: flex-end;
        justify-content: center;
        width: 100%;
        height: 0;

        .Popup {
            &-Content {
                max-height: none;
                height: auto;
                width: 100%;
                max-width: 100%;

                @include mobile {
                    max-height: 100vh;
                    position: fixed;
                    bottom: 0;
                    padding: 1rem 1remcalc(env(safe-area-inset-bottom) + 1rem);
                }
            }
        }

        .GdprCookiesPopup-Wrapper {
            padding: 16px;

            @include mobile {
                padding: 0;
            }
        }

        .GdprCookiesPopup-Buttons {
            flex-direction: column;
            justify-items: center;
            gap: 16px;
            justify-content: center;
        }

        .GdprCookiesPopup-Groups {
            flex-direction: row;
        }
    }

    &_isClassicBarTop {
        align-items: flex-start;

        .Popup {
            &-Content {
                inset-block-end: unset;
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &-Actions {
        display: grid;
        gap: 16px;
        height: max-content;

        @include mobile {
            padding: 0;
            gap: 0;
        }
    }

    &-Buttons {
        display: flex;
        flex-direction: column;
        width: 70vw;

        @include mobile {
            width: 100%;
            padding: 16px;
            padding-block-end: 32px;
            box-shadow: 1px 20px 80px 1px rgba(0, 0, 0, 0.9);
        }
    }

    &-Description {
        font-size: 16px;
        margin: 0;
        width: 70vw;

        @include mobile {
            overflow-y: auto;
            width: 100%;
            padding: 16px;
            max-height: 272px;
        }

        a {
            color: var(--gdpr-cookie-group-more-info-color);
        }

        &_isRussian {
            a {
                font-weight: 700;
            }
        }
    }

    &-Groups {
        @include desktop {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;

            @include mobile {
                direction: rtl;
                margin-inline-start: 5px;
                padding-block-start: 25px;
            }

            &::-webkit-scrollbar {
                width: 0;

                &-thumb {
                    background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                    border-radius: 2px;
                }

                &-track {
                    background-color: var(--gdpr-cookies-group-scrollbar-track-color);
                    box-shadow: none;
                }
            }
        }
    }
}

.GdprCookiesPopup_isPopup {
    .Popup-Content {
        padding: 0;
        box-shadow: 1px 20px 80px 1px rgba(0, 0, 0, 0.9);

        @include mobile {
            padding: 1rem 1remcalc(env(safe-area-inset-bottom) + 1rem);
            top: 0;
            position: fixed;
        }
    }

    .GdprCookiesPopup-Groups {
        padding: var(--popup-content-padding);

        @include mobile {
            max-height: none;
            overflow-y: scroll;
            padding-block-end: 300px;
        }
    }

    .GdprCookiesPopup {
        &-Actions {
            padding: var(--popup-content-padding);
            padding-block-start: 8px;
            box-shadow: 1px 20px 80px 1px rgba(0, 0, 0, 0.9);
            background-color: #fff;
            width: 100%;

            @include mobile {
                padding: 16px;
                padding-block-start: 0;
                padding-block-end: 32px;
                bottom: 0;
                position: fixed;
                height: 212px;
            }
        }

        &-Buttons {
            width: 100%;

            .Button {
                margin-block-start: 16px;
            }

            @include mobile {
                padding: 0;
                box-shadow: none;
            }
        }

        &-Description {
            display: none;
            padding: 16px;
        }
    }
}
