/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--primary-base-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--primary-base-color));
}

.Field {
    margin-block-start: 16px;

    &>input {
        height: 52px;
        line-height: 24px;
    }

    &-Label {
        font-size: 14px;
        font-weight: 500;

        &_isRequired {
            color: var(--primary-error-color);
        }
    }

    &-CheckboxLabel, &-RadioLabel {
        span {
            display: inline-flex;
            align-items: center;
            font-size: 16px;
        }

        &:hover {
            cursor: pointer;

            @include desktop {
                color: var(--color-black);
            }
        }
    }

    &-ErrorMessages {
        font-size: 14px;
        line-height: 20px;
    }

    &_type {
        &_checkbox,
        &_radio {
            margin-block-start: 22px;
        }

        &_text,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {
                input,
                textarea,
                select {
                    border: 1px solid var(--body-content-secondary-color);
                }
            }

            input,
            textarea,
            select {
                &::placeholder {
                    color: var(--body-content-secondary-color);
                    opacity: 1;
                }

                &:-ms-input-placeholder {
                    color: var(--body-content-secondary-color);
                }

                &::-ms-input-placeholder {
                    color: var(--body-content-secondary-color);
                }

                &:disabled {
                    border: 1px solid var(--body-content-disabled-color);

                    &::placeholder {
                        color: var(--body-content-disabled-color);
                    }
                }
            }
        }
    }

    [type='checkbox'], [type='radio'] {
        +.input-control {
            flex-shrink: 0;
            border: 1px solid var(--checkbox-border-color);
            color: var(--checkbox-border-color);
            margin-inline-end: 12px;
            transition-property: background-color, border-color, border-width;
        }

        &:hover {
            & + .input-control,
            &:checked + .input-control {
                @include desktop {
                    border-color: var(--primary-hover-color);
                }
            }

            &:disabled + .input-control {
                border-color: var(--body-content-disabled-color);
                color: var(--body-content-disabled-color);
                cursor: auto;
            }
        }

        &:disabled {
            & ~ span {
                color: var(--body-content-disabled-color);
            }
        }

        &:disabled + .input-control {
            border-color: var(--body-content-disabled-color);
            color: var(--body-content-disabled-color);
            cursor: auto;
            opacity: .7;
        }
    }

    [type='checkbox'] {
        &:hover + .input-control {
            cursor: pointer;

            &::after {
                --checkmark-color: transparent;
            }
        }

        &:checked {
            + .input-control {
                border-color: var(--checkbox-border-color-active);
                background-color: var(--checkbox-border-color-active);

                &::after {
                    --checkmark-color: var(--color-white);
                }
            }

            &:hover + .input-control {
                @include desktop {
                    background-color: var(--primary-hover-color);
                }
            }

            &:disabled {
                &:hover + .input-control {
                    background-color: var(--body-content-disabled-color);
                    border-color: var(--body-content-disabled-color);
                }

                + .input-control {
                    border: 2px solid var(--body-content-disabled-color);
                    background-color: var(--body-content-disabled-color);
                    cursor: auto;
                    opacity: .7;
                }
            }
        }
    }

    [type='radio'] {
        + .input-control {
            border-radius: 50%;

            &::after {
                box-shadow: none;
                height: 0;
                width: 0;
            }
        }

        &:hover + .input-control {
            cursor: pointer;
        }

        &:checked {
            + .input-control {
                border: 5px solid var(--checkbox-border-color-active);
            }

            &:disabled {
                + .input-control {
                    border: 5px solid var(--body-content-disabled-color);
                }
            }
        }
    }
}
