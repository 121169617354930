/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    @include mobile {
        --header-height: 84px;
        --header-nav-height: 84px;
    }

    @include desktop {
        --header-top-menu-height: 0px;
        --header-nav-height: 100px;
    }
}

.Header {
    transition: transform 0.4s;

    @include mobile {
        border-block-end: none;
    }

    &-Nav {
        padding-inline-start: 16px;
        padding-inline-end: 16px;
        justify-content: space-between;
        border-block-end: 1px solid var(--stroke-color);

        @include desktop {
            padding-inline-start: 52px;
            padding-inline-end: 52px;
        }

        @include tablet {
            padding-inline-start: 32px;
            padding-inline-end: 32px;
        }

        .SearchField {
            @include desktop {
                max-width: 320px;
                margin: 0 24px;
            }

            @include mobile {
                margin: 0;
                padding: 16px 2px 0;
            }
        }
    }

    &-CompareButtonWrapper {
        margin-inline-start: 0;
    }

    &-CompareTooltip {
        position: absolute;
        font-size: 14px;
        font-family: "Moller", sans-serif;
    }

    &-CompareCount {
        @include mobile {
            inset-inline-end: 32px;
        }
    }

    .CurrencySwitcher {
        margin-inline-start: 20px;
    }

    &-Title {
        &_isVisible {
            @include mobile {
                padding: 0 48px 8px;
                max-width: unset;
                text-align: start;
                background-color: var(--color-white);
                inset-block-start: calc(var(--header-height) + 120px);
                transition: unset;
            }
        }
    }

    &-Button {
        &_type {
            &_close {
                @include mobile {
                    inset-block-start: calc(var(--header-height) + 61px);
                    position: absolute;
                    z-index: 1;
                }
            }
        }
    }

    &-Links {
        display: none;
        margin: 0 0 0 auto;

        a {
            font-size: 16px;
            font-weight: 400;
            margin: 0 16px;
        }

        .Button {
            width: unset;
            background: #BABAFF;
            border-color: #BABAFF;
            color: var(--color-black);
        }

        @include wide-desktop {
            display: flex;
            align-items: center;
        }
    }

    &_isHidden {
        transform: translateY(-100%);
    }

    &-Wrapper {
        margin-block-end: calc(var(--header-total-height));

        @include mobile {
            margin-block-end: calc(var(--header-total-height) + 40px);
        }
    }
}
