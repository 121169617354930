/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.SearchItem {
    margin: 16px 0;

    &-CustomAttribute {
        font-size: 14px;
        line-height: 20px;
        margin-block-end: 4px;
    }

    &-Title {
        font-size: 16px;
        font-weight: 500;
    }
}
