/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

.PageBuilderSlider {
    .Slider {
        &-Arrow {
            &_isPrev {
                inset-inline-start: 0;
            }

            &_isNext {
                inset-inline-end: 0;
            }
        }

        .pagebuilder-slide-wrapper {
            .pagebuilder-slide-button {
                height: auto !important;
                width: auto !important;
                max-width: 80%;
                padding: 10px 24px;
                transition-property: color, background-color, border;
                transition-duration: 0.25s;
                transition-timing-function: ease-in-out;

                @include desktop {
                    padding: 12px 35.5px;

                    &:hover {
                        padding: 12px 35.5px;
                    }
                }
            }
        }
    }
}
