/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.SliderArrow {
    &.ChevronIcon {
        fill: none;
        stroke-width: 2px;
        stroke: var(--body-content-secondary-color);
        stroke-linejoin: round;
    }
}
