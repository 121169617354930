/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.StickyFooter {
    &-Wrapper {
        position: -webkit-sticky;
        position: sticky;
        inset-block-end: 0;
        padding: 0;
        height: 72px;
        background: #000000;
        z-index: 100;

        @include mobile {
            position: relative;
            height: 224px;
        }
    }

    &-List {
        display: flex;
        height: 72px;
        padding: 0;

        @include mobile {
            flex-direction: column;
            align-items: center;
            height: 224px;
        }

        li:last-child {
            margin-block-end: 0;
        }
    }

    &-Item {
        display: flex;
        flex: 1;
        margin-block-end: 0;

        @include mobile {
            width: 100%;
        }
    }

    &-Item::before {
        content: none;
    }

    &-Item::after {
        content: none;
    }

    &-Button {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        background: #000000;

        span {
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
        }

        img {
            width: 24px;
            height: auto;
            fill: #FFFFFF;
        }

        @include mobile {
            justify-content: space-between;
            padding-inline-start: 16px;
            padding-inline-end: 16px;
        }
    }

    &-Button:hover {
        color: #0000FF;
        background: #FFFFFF;

        span {
            color: #0000FF;
        }
    }

    &-CarIcon {
        width: 24px;
        height: 24px;
        margin-inline-start: 16px;
        background: url("../../style/icons/StickyFooter/car_white.svg") no-repeat;
        object-fit: contain;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    &-Button:hover &-CarIcon {
        background: url("../../style/icons/StickyFooter/car_blue.svg") no-repeat;
    }

    &-RepairIcon {
        width: 24px;
        height: 24px;
        margin-inline-start: 16px;
        background: url("../../style/icons/StickyFooter/repair_white.svg") no-repeat;
        object-fit: contain;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    &-Button:hover &-RepairIcon {
        background: url("../../style/icons/StickyFooter/repair_blue.svg") no-repeat;
    }

    &-FormIcon {
        width: 24px;
        height: 24px;
        margin-inline-start: 16px;
        background: url("../../style/icons/StickyFooter/form_white.svg") no-repeat;
        object-fit: contain;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    &-Button:hover &-FormIcon {
        background: url("../../style/icons/StickyFooter/form_blue.svg") no-repeat;
    }

    &-ContactIcon {
        width: 24px;
        height: 24px;
        margin-inline-start: 16px;
        background: url("../../style/icons/StickyFooter/contact_white.svg") no-repeat;
        object-fit: contain;

        @include mobile {
            margin-inline-start: 0;
        }
    }

    &-Button:hover &-ContactIcon {
        background: url("../../style/icons/StickyFooter/contact_blue.svg") no-repeat;
    }
}
