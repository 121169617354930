/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.SearchOverlay {
    &-Results {
        & > p {
            margin-block-start: 1rem;
        }

        @include desktop {
            padding-block-start: 0px;
            padding-block-end: 0px;
            border: 1px solid var(--input-border-color);
            border-block-start: none;

            ul {
                overflow-x: hidden;

                &::-webkit-scrollbar {
                    width: 6px;
                    background-color: var(--background-stroke-color);
                    overflow-x: hidden;

                    &-thumb {
                        background-color: var(--body-content-secondary-color);
                    }
                }
            }
        }

        @include mobile {
            margin-block-start: 4rem;
        }
    }
}
