/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

.StoreDetails {
    &-Container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;

        @include mobile {
            grid-template-columns: 1fr;
            grid-gap: 5px;
        }
    }

    &-Text {
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        display: block;
        margin-bottom: 10px;
        line-height: 1;
        text-align: center;
    }

    &-Name {
        text-align: center;
    }

    &-MoreDetailsContainer {
        display: grid;
        grid-template-columns: auto auto auto auto;
        grid-gap: 10px;
        padding-top: 10px;

        div {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;

            label {
                text-align: left;
            }
        }
    }

    &-MoreDetails1 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-MoreDetails2 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-MoreDetails3 {
        grid-column-start: 2;
        grid-column-end: 5;
    }

    &-Label {
        font-weight: bold;
    }

    &-WorkingDays {
        padding-bottom: 10px;
    }

    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }
}
