/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --posts-details-title-size: 28px;
    --post-box-background-color: #fafafa;
}
$icon-arrow-black: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path stroke="%23393748" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h14M8 1l7 7-7 7"/></svg>');

.PostsDetails {
    margin-block-start: 0;

    .BlogSocialLinks-SocialTitle {
        @include desktop {
            margin: 15px 0 20px 18px;
        }
    }

    &-Wrapper {
        padding-block-end: 30px;

        @include mobile {
            padding-block-start: 5px;
        }

        @include desktop {
            display: grid;
            grid-template-columns: 30% 70%;
            grid-column-gap: 30px;
        }
    }

    &-Info {
        @include desktop {
            width: 70%;
        }
    }

    &-ToBlog {
        @include mobile {
            background: $icon-arrow-black no-repeat center;
            position: absolute;
            height: 18px;
            width: 18px;
            transform: rotate(180deg);
            margin-block-start: 7px;
            z-index: 1;
        }
    }

    &-ColumnWrapper {
        @include mobile {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-Main {
        grid-column: 2;
        grid-row: 1;

        @include desktop {
            margin-inline-end: 30px;
        }
    }

    .BlogSocialLinks {
        &-SocialBlock {
            display: none;

            @include mobile {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;

        .BlogSocialLinks-SocialBlock {
            display: none;

            @include desktop {
                display: initial;
            }
        }
    }

    &-Description {
        margin-block-end: 20px;

        @include mobile {
            order: 1;
        }

        @include desktop {
            flex-basis: 70%;
        }

        .Image {
            border-radius: 16px;
        }
    }

    &-Sidebar {
        grid-column: 1;
        grid-row: 1;

        @include desktop {
            margin-block-start: 7px;
        }

        .BlogRecentPosts {
            &-Wrapper {
                flex-direction: column;
                gap: 16px;
            }

            &-Title {
                margin: 16px 0
            }

            &-Button {
                display: none;
            }

            &-PostTitle {
                font-size: var(--paragraph-font-size);
                display: block;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;

                @include mobile {
                    -webkit-line-clamp: unset;
                }
            }

            &-PostCard {
                @include mobile {
                    &:nth-child(n+3) {
                        display: none;
                    }
                }

                @include desktop {
                    gap: 24px;

                    &:nth-child(odd) {
                        .BlogRecentPosts-ImageWrapper {
                            order: 1;
                        }

                        .BlogRecentPosts-ContentWrapper {
                            order: 2;
                        }
                    }
                }
            }

            &-ImageWrapper {
                .Image {
                    &-Image {
                        height: 132px;
                        width: 100%;

                        @include mobile {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            &-Tags {
                display: none;
            }
        }
    }

    &-Title {
        font-size: var(--posts-details-title-size);
        line-height: 28px;
        text-align: start;
        margin: 0;

        @include mobile {
            padding-inline-start: 40px;
            margin: 3px 0;
        }
    }

    &-Date,
    &-Author {
        display: block;
        font-size: 14px;
        text-align: start;
    }

    &-ContentPlaceholder {
        &::after {
            content: 'aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa';
            word-break: break-all;
        }
    }

    &-DatePlaceholder {
        &::after {
            content: 'aaaa/aa/aa';
        }
    }

    &-Iframe {
        position: relative;
        padding-block-end: 56.25%; /* 16:9 */
        height: 0;

        iframe {
            position: absolute;
            inset-start: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-CommentSection {
        margin-block-end: 20px;

        @include desktop {
            margin-block-start: 40px;
            grid-column: 2;
            margin-inline-end: 40px;
        }
    }

    &-Content {
        font-size: 14px;
        margin-block-end: 15px;

        & .pagebuilder-mobile-hidden {
            @include mobile {
                display: none;
            }
        }

        & .pagebuilder-mobile-only {
            display: none;

            @include mobile {
                display: block;
            }
        }

        .embed-container {
            position: relative;
            padding-block-end: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;
        }

        h2 {
            font-size: 20px;
        }

        .wp-text-box {
            line-height: 21px;
            margin: 20px 0;
            padding: 10px;
            background-color: var(--post-box-background-color);
            border-radius: 14px;
        }

        .nested-container {
            @include desktop {
                display: flex;
            }

            .grid12-6 {
                @include desktop {
                    flex: 50%;

                    &:first-child {
                        padding-inline-end: 20px;
                    }

                    &:last-child {
                        padding-inline-start: 20px;
                    }
                }
            }

            .grid12-4 {
                @include desktop {
                    flex: 33%;
                    padding: 0 10px;

                    &:first-child {
                        padding-inline-start: 0;
                    }

                    &:last-child {
                        padding-inline-end: 0;
                    }
                }
            }
        }

        hr {
            height: 1px;
            margin: 10px 0;
            text-indent: -9999em;
            overflow: hidden;
        }
    }

    div[data-content-type='video'] {
        font-size: 0;

        .pagebuilder-video-inner {
            box-sizing: border-box;
            display: inline-block;
            width: 100%;
        }

        .pagebuilder-video-container {
            border-radius: inherit;
            overflow: hidden;
            padding-block-start: 56.25%;
            position: relative;
        }

        iframe,
        video {
            height: 100%;
            inset-start: 0;
            position: absolute;
            width: 100%;
        }
    }

    &-BackLink {
        svg {
            vertical-align: middle;
        }
    }
}
