/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-search-field-background: #{$default-secondary-base-color};
    --blog-search-field-border-color: #{$default-secondary-dark-color};
}

$icon-search-black: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="%23393748" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zM21 21l-4.35-4.35"/></svg>');

@mixin search-icon {
    &::before {
        background: #{$icon-search-black} no-repeat center;
        background-size: contain;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        transition: background-image 200ms ease-out;
        width: 24px;
        height: 24px;
    }
}

.BlogSearchBar {
    &-Search {
        @include search-icon;

        &::before {
            font-size: 12px;
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
            content: '';
        }
    }

    &-SearchWrapper {
        flex-grow: 1;
        text-align: right;
    }

    &-SearchField {
        font-size: 14px;
        width: 100%;
        max-width: 100%;
        padding: 14px 24px 14px 50px;
        transition-duration: 200ms;
        transition-property: border-radius;
        border-width: 1px;
        border-color: transparent;
        border-radius: 2rem;
        background-color: var(--blog-search-field-background);
        will-change: border-radius;

        &:focus {
            border-color: transparent;
            border-radius: 2rem 2rem 0 0;
        }
    }
}
