/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ListIcon {
    fill: var(--color-white);
    stroke: var(--body-content-secondary-color);
    stroke-width: 2px;

    &:hover {
        @include desktop {
            fill: var(--color-white)
        }
    }

    &_isActive {
        stroke: var(--color-black);
    }
}
