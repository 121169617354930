/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
@mixin facebook() {
    background-image: url('../../../../../src/style/icons/Footer/Facebook.svg');
}

@mixin instagram() {
    background-image: url('../../../../../src/style/icons/Footer/Instagram.svg');
}

@mixin linkedin() {
    background-image: url('../../../../../src/style/icons/Footer/Linkedin.svg');
}

@mixin rss($color) {
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 24 24"%3E%3Cpath style="fill:#{$color}" d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-12.832 20c-1.197 0-2.168-.969-2.168-2.165s.971-2.165 2.168-2.165 2.167.969 2.167 2.165-.97 2.165-2.167 2.165zm5.18 0c-.041-4.029-3.314-7.298-7.348-7.339v-3.207c5.814.041 10.518 4.739 10.561 10.546h-3.213zm5.441 0c-.021-7.063-5.736-12.761-12.789-12.792v-3.208c8.83.031 15.98 7.179 16 16h-3.211z"/%3E%3C/svg%3E');
}

$dark-blue-encoded: '%23036bb9';
$blue-encoded: '%230384e5';

.BlogSocialLinks {
    &-SocialTitle {
        @include desktop {
            margin: 20px 0 20px 18px;
        }
    }

    &-Buttons {
        display: flex;
        margin-top: 7px;
    }

    &-SocialButtons {
        width: 38px;
        height: 38px;
        margin: 5px 0 0 7px;

        &:hover {
            .BlogSocialLinks {
                &-Facebook {
                    &::after {
                        opacity: 0.6;
                    }
                }

                &-Instagram {
                    &::after {
                        opacity: 0.6;
                    }
                }

                &-Linkedin {
                    &::after {
                        opacity: 0.6;
                    }
                }

                &-Rss {
                    &::after {
                        @include rss($dark-blue-encoded);
                    }
                }
            }
        }
    }

    &-Facebook,
    &-Instagram,
    &-Linkedin,
    &-Rss {
        &::after {
            background-size: contain;
            content: '';
            height: 18px;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translate(-50%, -50%);
            transition: background-image 200ms ease-out;
            width: 18px;

            @include facebook();
        }
    }


    &-Instagram {
        &::after {
            @include instagram();
        }
    }

    &-Linkedin {
        &::after {
            @include linkedin();
        }
    }

    &-Rss {
        &::after {
            @include rss($blue-encoded);
        }
    }
}
