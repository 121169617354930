/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

.BlogCommentForm {
    @include mobile {
        margin-bottom: 40px;
    }

    textarea,
    input {
        width: 100%;
    }

    &-PersonalInfo {
        margin-top: 20px;
        margin-bottom: 20px;

        @include desktop {
            display: grid;
            grid-column-gap: 20px;
        }
    }

    .Field {
        &-Wrapper{
            &_type_text {
                grid-row: 1 !important;

                &:first-child {
                    grid-column: 1 !important;
                }

                &:last-child {
                    grid-column: 2 !important;
                }
            }
            &_type_email {
                grid-row: 1 !important;
                grid-column: 2 !important;
            }

            &_type_textarea {
                margin-top: 0;
            }

            input,
            textarea {
                border-radius: 16px;
            }
        }
    }

    &-FormTitle {
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
    }

    &-ButtonWrapper {
        margin-block-start: 20px;
    }
}
