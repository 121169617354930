/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.VideoThumbnail {
    &-Thumbnail {
        padding-block-end: 375px;

        @include mobile {
            padding-block-end: 245px;
        }

        @include small-mobile {
            padding-block-end: 185px;
        }
    }
}
