/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --default-notification-height: 40px;
    --notification-info-color: var(--imported_notificationInfo_color, var(--color-white));
    --notification-info-background: var(--imported_notificationInfo_background_color, var(--primary-attention-color));
    --notification-success-background: var(--imported_notificationSuccess_background_color, var(--primary-base-color));
}

.Notification {
    padding: 8px 8px 8px 20px;
    border-radius: 0;

    &-Text {
        font-weight: 400;
        font-size: 16px;
    }

    &-Button {
        @include desktop {
            inset-block-start: 50%;
            transform: translateY(-50%);
        }

        &::before, &::after {
            height: 12px;
        }
    }
}
