/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

#html-body {
    .flexibleFormBlock,
    .TestDrivePagePopup {
        .Popup-Content {
            padding: 5.8rem 8rem;
            text-align: center;

            @include mobile {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 16px;
            }

            &-info {
                max-width: 592px;
                font-size: 1.8rem;

                &-line {
                    display: block;
                }

                strong {
                    font-weight: 700;
                }
            }

            &-Button {
                margin-block-start: 3.2rem;
                width: 252px;

                @include mobile {
                    margin-block-start: 24px;
                }
            }
        }
    }

    .TestDrivePagePopup {
        .Popup-CloseBtn {
            display: none;
        }

        .Popup-Content {
            &-Actions {
                display: flex;
                justify-content: space-between;
                gap: 32px;

                @include mobile {
                    flex-direction: column;
                    gap: 0;
                }
            }
        }
    }

    .CmsPage {
        a,
        button,
        div {
            &.pagebuilder-button-primary {
                text-transform: none;
                font-weight: 500;
                font-size: 16px;
                width: 252px;
                min-width: 252px;
                max-width: 252px;

                &:hover {
                    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
                }
            }
        }

        [data-content-type='row'][data-appearance='full-width'] > .row-full-width-inner {
            max-width: var(--content-wrapper-width);

            @include desktop {
                padding-inline: 52px;
            }

            @include tablet {
                padding-inline: 32px;
            }

            @include mobile {
                padding-inline: 16px;
            }
        }

        [data-content-type="buttons"][data-appearance="inline"] {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 10px;

            [data-element="link"] {
                width: initial;
                margin: initial;
            }
        }

        &-Wrapper {
            margin-block-start: 0;

            &_page_width {
                &_full [data-content-type="row"][data-appearance="contained"],
                &_default {
                    max-width: var(--content-wrapper-width);

                    @include desktop {
                        padding-inline: 52px;
                    }

                    @include tablet {
                        padding-inline: 32px;
                    }

                    @include mobile {
                        padding-inline: 16px;
                    }
                }

                &_full {
                    .BlogRecentPosts-PostDescription div[data-content-type="row"][data-appearance="contained"] {
                        padding-inline: 0;

                        @include desktop {
                            padding-inline: 0;
                        }
                    }
                }
            }

            [data-content-type="column"].book-the-service,
            [data-content-type="column"].body-and-paint,
            [data-content-type="column"].leasing {
                display: grid;
                grid-template-columns: minmax(462px, 50%) minmax(462px, 50%);
                column-gap: 52px;
                row-gap: 32px;

                @include tablet {
                    display: flex;
                    gap: 80px;
                }

                @include mobile {
                    display: flex;
                    gap: 80px;
                }
            }
        }

        .Slider-Crumbs {
            margin-block-end: 0;
        }

        @include mobile {
            margin-block-start: 0;
        }
    }
}

.elfsight-app-cefc0b38-ea18-4f97-b66a-7a1a0ad2abfa {
    margin-block-start: 3rem;

    @include mobile {
        margin-block-start: 20px;
    }
}
