/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --left-range-progress: 0;
    --right-range-progress: 0;
}

@mixin thumb {
    background: var(--body-content-secondary-color);
    border: 1px solid var(--body-content-secondary-color);
    border-radius: 50%;
    cursor: pointer;
    height: 16px;
    pointer-events: auto;
    width: 16px;
    -webkit-appearance: none;
}

.PriceSlider {
    margin-block-end: 20px;

    &-InputWrapper {
        display: flex;
        margin: 30px 0 35px;
        width: 100%;
    }

    &-Separator {
        align-items: center;
        cursor: default;
        display: flex;
        font-size: 19px;
        justify-content: center;
        width: 130px;
    }

    &-Input {
        align-items: center;
        display: flex;
        height: 45px;
        width: 100%;

        &Min,
        &Max {
            --input-padding: 15px 5px 14px;

            border: 1px solid var(--input-border-color);
            cursor: text;
            font-size: 14px;
            font-weight: normal;
            height: 100%;
            pointer-events: auto;
            text-align: center;
            width: 100%;
        }
    }

    &-FilteredPrice {
        margin-block-end: 13px;
        color: var(--body-content-secondary-color);
        font-size: var(--paragraph-font-size);
    }

    &-SliderTitle {
        font-weight: 500;
    }

    &-Slider {
        background: var(--background-stroke-color);
        border-radius: 3px;
        height: 6px;
        position: relative;

        &Progress {
            background: var(--body-content-secondary-color);
            border-radius: 3px;
            inset-inline-end: var(--right-range-progress);
            inset-inline-start: var(--left-range-progress);
            height: 100%;
            position: absolute;
        }
    }

    &-Range {
        &Input {
            position: relative;
        }

        &Min,
        &Max {
            background: none;
            border: none;
            height: 5px;
            inset-block-start: -4px;
            min-height: auto;
            padding: 0;
            top: -6px;
            pointer-events: none;
            position: absolute;
            width: 100%;
            -moz-appearance: none;
            -webkit-appearance: none;

            &:not(:first-of-type) {
                margin-block-start: 0;
            }

            &:focus {
                border: none;
            }

            &::-webkit-slider-thumb {
                @include thumb;
            }

            &::-moz-range-thumb {
                @include thumb;
            }
        }
    }
}
