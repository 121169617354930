/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

 .StoreFinder {
    margin-block-start: 0;

    &-Wrapper {
        @include mobile {
            display: block;
            padding: 0;
        }
    }

    &-Heading {
        h1 {
            padding: 26px 0 0;

            @include mobile {
                padding: 18px 21px 0;
            }
        }
    }

    &-MainContent {
        @include mobile {
            background-color: $color-wild-sand;
        }
    }

    &-SearchContainer {
        width: 100%;

        @include desktop {
            max-width: 430px;
            margin-right: 20px;
        }

        .SearchField {
            margin: 0;
            width: 100%;

            @include mobile {
                margin-bottom: 9px;
            }
        }
    }

    &-StoresSection{
        background-color: $color-wild-sand;
    }
&-ShowMoreBtn{
    width: 100%;
    padding: 1rem;
    margin-top: 3.2rem;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--primary-base-color);
    border: 1px solid var(--primary-base-color);
    cursor: pointer;
    &:hover{
        opacity: 0.5;
    }
}
    &-Map {
        z-index: 0;

        @include desktop {
            max-height: 530px;
            height: 100vh;
        }

        .leaflet-container {
            border: 1px solid rgb(219, 219, 219);
            height: 100%;

            @include mobile {
                height: 400px;
            }

            .leaflet-control-zoom-in,
            .leaflet-control-zoom-out,
            .leaflet-popup-content {
                margin: 0; // margin set by 'webpack:///./src/app/style/base/_link.scss'
            }

            .leaflet-shadow-pane {
                img {
                    display: none;
                }
            }

            .dealership-type-logo{
                width: 50px;
            }
        }

        .leaflet-popup {
            margin-left: 1.25rem;

            @include mobile {
                margin: 0;
            }

            &-tip-container {
                display: none;
            }

            &-content {
                &-wrapper {
                    box-shadow: none;
                    border-radius: 3px;
                    min-width: 200px;
                    width: fit-content;
                }
            }
        }

        .leaflet-marker-icon {
            border: 0;
            background-color: transparent;
            margin-top: -50px !important;
        }

        .StoreFinder-Store {
            padding: 15px;
        }
    }

    &-SearchBarSection {
        padding: 26px 15px 9px;

        @include desktop {
            display: flex;
            justify-content: space-between;
            padding: 28px 1px 31px;
        }
    }

    &-SelectList {
        background-color: var(--color-storefinder-white);

        @include mobile {
            margin-bottom: 0;
        }

        @include desktop {
            display: flex;
            justify-content: end;
            width: 100%;
        }
    }

    &-Select {
        width: calc(50% - 10px);
        display: inline-block;

        @include mobile {
            display: block;
            width: 100%;
        }

        &:last-child {
            margin-left: 20px;

            @include mobile {
                margin-left: 0;
            }
        }

        &-Cities,
        &-Dealerships {
            width: 100%;
            display: flex;
            align-items: center;

            .Field-Wrapper {
                width: 100%;
            }

            .Field {
                margin-top: 0;

                select {
                    height: 48px;
                }
            }

            @include desktop {
                max-width: 380px;
            }

            @include mobile {
                margin-bottom: 9px;
            }
        }

        .Select {
            &-Form {
                font-size: 12px;
                line-height: 1;
                padding: 13px 50px 13px 20px;
            }

            &-Elements {
                li {
                    font-size: 12px;
                    padding: 0 50px 0 20px;
                    height: 40px;
                }
            }

            &-Wrapper:hover > ul li {
                @include mobile {
                    max-height: 40px;
                }
            }
        }
    }

    &-StoreCards {
        max-height: 700px;
        overflow-y: auto;

        @include mobile{
            max-height: none;
            overflow: none;
        }
    }

    &-Store {
        border-bottom: 1px solid $color-dusty-gray;
        padding: 59px 20px 27px;

        @include desktop {
            padding: 55px 35px 43px;
            display: flex;
            justify-content: space-between;
            gap: 20px;
        }

        &_isActive {
            border: solid 1px var(--primary-base-color);
        }

        .Button {
            margin-top: 10px;
            justify-content: flex-start;
            display: block;
            text-decoration: none;

            &::after {
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                bottom: 0;
                left: 0;
                position: absolute;
            }

            &:hover {
                color: var(--link-hover);
            }
        }

        a {
            font-weight: normal;
            display: block;
        }
    }

    &-StoreInfo {
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        line-height: 1;
        word-break: break-word;

        :last-child {
            margin-bottom: 0;
        }

        a {
            font-size: 14px;
        }

        @include mobile {
            font-size: 16px;
        }

        img{
            width: 24px;
            height: 24px;
            margin-right: 1rem;
        }

        &_type {
            &_name {
                font-weight: var(--h3-font-weight);
                font-size: var(--h3-font-size);
                margin-bottom: 20px;
                cursor: pointer;
                @include mobile {
                    font-size: 16px;
                }
            }
            &_hours {
                @include mobile {
                    .pagebuilder-column-group {
                        flex-direction: column;

                        .pagebuilder-column {
                            /* stylelint-disable-next-line */
                            width: 100% !important; // Important used to override page-builder inline style
                            margin-bottom: 30px;

                            &:empty {
                                margin: 0;
                            }
                        }
                    }
                }

                @include desktop {
                    .pagebuilder-column-group {
                        position: relative;
                        top: -6px;

                        .pagebuilder-column {
                            width: 50%;
                        }
                    }
                }

                align-items: flex-start;

                > div {
                    width: 100%
                }

                p {
                    margin-block-end: 0;
                    margin-inline-end: 6px;
                    font-size: 14px;
                    margin-bottom: 13px;

                    &:first-child {
                        min-height: 48px;
                        margin-bottom: 0;
                        display: flex;
                        align-items: center;
                    }
                }

                strong {
                    font-size: 16px;
                }
            }

            &_number {
                margin-bottom: 0;
            }
        }
    }

    &-StoreInfoWrapper,
    &-StoreHoursWrapper {
        flex-grow: 1;
        flex-basis: 0;

        @include mobile {
            margin-bottom: 20px;
        }
    }

    &-StoreInfoWrapper {
        @include desktop {
            max-width: 35%;
        }
    }

    &-GetDirectionWrapper {
        @include desktop {
            display: flex;
            align-items: end;
        }
    }

    &-DirectionsButton {
        button {
            width: 142px;
            font-size: 17px;
            font-weight: 500;
            border: 1px solid #2F2FFF;
            height: 32px;
            line-height: 20px;
            border-radius: 15px;
            cursor: pointer;
            background-color: $white;

            &:hover {
                box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
            }
        }
    }

    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }

    .ContentWrapper {
        @include mobile {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &-ContactUsForm {
        max-width: 1336px;
        width: 100%;
        padding: 32px;
        border: 1px solid var(--background-stroke-color);
        display: flex;
        margin-block: 32px 104px;

        @include mobile {
            margin: 80px auto 80px;
            width: 90%;
            border: none;
            padding: 0;
        }

        .pagebuilder {
            &-column {
                @include mobile {
                    /* stylelint-disable-next-line */
                    width: 100% !important;
                }

                &-group {
                    display: flex;

                    @include mobile {
                        flex-direction: column;
                    }
                }
            }
        }

        .contact-us-form {
            &-text {
                max-width: 578px;
                width: 100%;
                margin-inline-end: 106px;

                @include mobile {
                    margin-inline-end: 0;
                }

                h2 {
                    margin-block-start: 0;

                    @include mobile {
                        font-size: var(--h2-font-size);
                    }
                }

                h3 {
                    margin-block-end: 24px;

                    @include mobile {
                        font-size: var(--h3-font-size);
                    }
                }

                p {
                    @include mobile {
                        margin-block-end: 24px;
                    }
                }
            }

            &-ru {
                .Field {
                    &-Label {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .FlexibleFormsWidget {
        margin-block: 0;
        max-width: 578px;
        width: 100%;

        form {
            display: flex;
            flex-direction: column;

            p, h2, h3, hr {
                display: none;
            }

            > div > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .Field {
                    &-Wrapper {
                        margin-block: 0 16px;

                        &_type_text {
                            max-width: 272px;
                            width: 100%;

                            @include mobile {
                                max-width: 100%;
                            }
                        }

                        &:nth-of-type(3) {
                            width: 100%;
                            max-width: none;
                        }

                        &_type_email {
                            max-width: none;
                            width: 100%;

                            @include mobile {
                                max-width: 100%;
                            }
                        }

                        &_type_textarea {
                            width: 100%;
                        }

                        &_type_select {
                            width: 100%;
                        }

                        .Field {
                            margin-block-start: 0;
                        }

                        input, textarea {
                            width: 100%;
                        }

                        textarea {
                            height: 104px;
                        }
                    }
                }

                .FieldGroup {
                    flex-direction: column;
                    gap: 14px;

                    &-Wrapper {
                        margin-block: 0 16px;
                    }

                    .Field {
                        margin-block: 0;

                        &-Wrapper {
                            margin-inline-end: 0;
                        }
                    }
                }
            }

            .FlexibleForms {
                &-required {
                    label::after {
                        content: " *";
                        color: #FF3939;
                    }
                }
            }

            button {
                max-width: 160px;
                width: 100%;
                align-self: flex-end;

                @include mobile {
                    margin-block-start: 24px;
                    max-width: 100%;
                }
            }
        }
    }
}

.LocalizationWrapper-ru_RU {
    .StoreFinder {
        &-StoreInfo {
            &_type {
                &_hours {
                    strong {
                        font-weight: 550;
                    }
                }
            }
        }

        &-DirectionsButton {
            button {
                font-weight: 550;
            }
        }
    }
}

.leaflet-popup-content {
    .StoreFinder {
        &-Store {
            padding: 10px;
            display: block;
        }

        &-StoreInfoWrapper {
            max-width: 100%;
        }

        &-GetDirectionWrapper {
            display: unset;
        }

        &-StoreInfo_type {
            &_hours {
                strong {
                    font-weight: 550;
                }
            }
        }

        @include mobile {
            font-size: 12px;

            &-StoreInfo_type {
                &_name {
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                &_number {
                    font-weight: bold;
                }

                &_workingdays {
                    margin: 5px 0;
                }

                &_hours {
                    margin: 0;

                    .pagebuilder-column-group {
                        flex-direction: row;

                        .pagebuilder-column {
                            margin: 0;
                        }
                    }

                    p {
                        font-size: 12px;
                        margin: 5px 0;
                    }
                }
            }
        }
    }
}
