:root {
    --default-input-padding: 14px 20px;
    --input-border-color: var(--imported_input_border_color, var(--body-content-secondary-color));
    --input-border-color-focus: var(--imported_input_border_color_focus, var(--input-border-color));
    --input-border-radius: var(--imported_input_border_radius, 0);
    --input-color: var(--imported_input_color, var(--body-content-color));
}

input,
select,
textarea {
    font-size: 16px;
}
