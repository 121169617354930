:root {
    --mobile-button-height: 32px;
    --button-on-banner-border: var(--background-white-color);
    --text-like-button-color: var(-body-content-color);
    --text-like-button-hover: var(--primary-hover-color);

    --primary-button-background: var(--primary-base-color);
    --primary-button-color: var(--background-white-color);

    --hollow-button-border-radius: 15px;
    --hollow-button-color: var(--color-black);

    --hollow-button-hover-border: var(--primary-hover-color);
    --hollow-button-hover-color: var(--color-black);
    --hollow-button-hover-border-radius: 15px;
    --hollow-button-hover-height: 32px;

    --disabled-hollow-button-color: var(--body-content-secondary-color);

    --button-line-height: 20px;
    --button-border-width: 1px;
    --button-border-radius: 15px;
    --button-height: 32px;
    --button-padding: var(--imported_buttons_padding, 24px);

    --button-hover-padding: var(--imported_hoverButtons_padding, var(--button-padding));
    --button-hover-border-radius: 15px;
    --button-hover-color: var(--background-white-color);
    --button-hover-background: var(--primary-base-color);
    --button-hover-border: var(--primary-base-color);
    --button-hover-height: 32px;

    --disabled-button-color: var(--body-content-disabled-color);
}

.Button {
    @include Button;
}
