/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ElfsightReOpen {
    background-color: blue;
    color: white;
    display: flex;
    position: fixed;
    inset-block-end: 70px;
    inset-inline-end: 0;
    z-index: 999;
    margin: 20px 0 10px;
    padding: 5px 10px 5px 20px;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    align-items: flex-start;
    gap: 5px;
    font-size: 14px;

    @include mobile {
        inset-block-end: 0;
    }

    &.hidden {
        display: none;
    }

    &:hover {
        cursor: pointer;
    }

    svg {
        max-width: 40px;
        max-height: 36px;
    }

    > div {
        display: flex;
        flex-direction: column;
    }
}
