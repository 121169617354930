.test-drive-form {
    max-width: 1336px;
    width: 100%;
    padding: 32px;
    border: 1px solid var(--background-stroke-color);
    display: flex;
    margin-block: 32px 104px;

    @include mobile {
        margin: 80px auto 80px;
        width: 90%;
        border: none;
        padding: 0;
    }

    &-ru {
        .Field {
            &-Label {
                font-weight: 700;
            }
        }
    }

    .widget {
        /* stylelint-disable-next-line */
        overflow-x: inherit !important;
    }

    .pagebuilder {
        &-column {
            @include mobile {
                /* stylelint-disable-next-line */
                width: 100% !important;
            }

            &-group {
                display: flex;

                @include mobile {
                    flex-direction: column;
                }
            }
        }
    }

    .test-drive-form-text {
        max-width: 578px;
        width: 100%;
        margin-inline-end: 106px;

        @include mobile {
            margin-inline-end: 0;
        }

        h2 {
            margin-block-start: 0;

            @include mobile {
                font-size: var(--h2-font-size);
                text-align: start;
            }

            strong {
                font-weight: 700;
            }
        }

        h3 {
            margin-block-end: 24px;

            @include mobile {
                font-size: var(--h3-font-size);
            }
        }

        p {
            @include mobile {
                margin-block-end: 24px;
            }
        }
    }

    .FlexibleFormsWidget {
        /* stylelint-disable-next-line */
        margin-block: 0 !important;
        max-width: 578px;
        width: 100%;

        form {
            display: flex;
            flex-direction: column;

            p, h2, h3, hr {
                display: none;
            }

            > div > div {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .Field {
                    margin-block: 0;

                    &-Wrapper {
                        margin-block: 0 16px;

                        &_type_text {
                            max-width: 272px;
                            width: 100%;

                            @include mobile {
                                max-width: 100%;
                            }
                        }

                        &:nth-child(3) {
                            max-width: none;
                        }

                        &_type_email {
                            max-width: none;
                            width: 100%;

                            @include mobile {
                                max-width: 100%;
                            }
                        }

                        &_type_textarea {
                            width: 100%;
                        }

                        &_type_url, &_type_select {
                            width: 100%;
                        }

                        input, textarea {
                            width: 100%;
                        }

                        textarea {
                            height: 104px;
                        }

                        ul {
                            /* stylelint-disable-next-line */
                            margin-inline-start: 0 !important;
                        }
                    }
                }

                .FieldGroup {
                    flex-direction: column;
                    gap: 14px;

                    &-Wrapper {
                        margin-block: 0 16px;
                    }

                    .Field {
                        margin-block: 0;

                        &-Wrapper {
                            margin-inline-end: 0;
                        }
                    }
                }
            }

            .FlexibleForms {
                &-required {
                    label::after {
                        content: " *";
                        color: #FF3939;
                    }
                }
            }

            button {
                max-width: 160px;
                width: 100%;
                align-self: flex-end;

                @include mobile {
                    margin-block-start: 24px;
                    max-width: 100%;
                }
            }
        }
    }
}
