/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.Popup {
    @include mobile {
        inset-block-start: 0;
        height: 100vh;
    }

    &-Content {
        padding: 16px;
        border-radius: 0;

        @include mobile {
            padding: 16px;
        }
    }

    &-Header {
        position: static;

        @include mobile {
            display: block;
        }
    }

    .NotificationList {
        width: 0;
    }

    .Popup-CloseBtn {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        inset-block-start: 16px;
        inset-inline-end: 16px;
        background-color: var(--background-hover-color);
    }
}

.scrollDisabled {
    @include mobile {
        position: fixed;
    }
}
