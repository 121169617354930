:root {
    // h1 desktop
    --h1-color: var(--imported_h1_color, var(--body-content-color));
    --h1-font-size: var(--imported_h1_font_size, 32px);
    --h1-font-weight: var(--imported_h1_font_weight, 700);
    --h1-line-height: var(--imported_h1_line_height, 36px);
    --h1-text-transform: var(--imported_h1_text_transform, none);

    // h1 mobile
    --h1-color-mobile: var(--imported_h1Mobile_color, var(--body-content-color));
    --h1-font-size-mobile: var(--imported_h1Mobile_font_size, 24px);
    --h1-line-height-mobile: var(--imported_h1Mobile_line_height, 32px);
    --h1-text-transform-mobile: var(--imported_h1_text_transform, none);

    // h2 desktop
    --h2-color: var(--imported_h2_color, var(--body-content-color));
    --h2-font-size: var(--imported_h2_font_size, 24px);
    --h2-font-weight: var(--imported_h2_font_weight, 700);
    --h2-line-height: var(--imported_h2_line_height, 32px);
    --h2-text-transform: var(--imported_h1_text_transform, none);

    // h2 mobile
    --h2-color-mobile: var(--imported_h2Mobile_color, var(--body-content-color));
    --h2-font-size-mobile: var(--imported_h2Mobile_font_size, 20px);
    --h2-line-height-mobile: var(--imported_h2Mobile_line_height, 24px);
    --h2-text-transform-mobile: var(--imported_h1_text_transform, none);

    // h3 desktop
    --h3-color: var(--imported_h3_color, var(--body-content-color));
    --h3-font-size: var(--imported_h3_font_size, 18px);
    --h3-font-weight: var(--imported_h3_font_weight, 700);
    --h3-line-height: var(--imported_h3_line_height, 24px);
    --h3-text-transform: var(--imported_h1_text_transform, none);

    // h3 mobile
    --h3-color-mobile: var(--imported_h3Mobile_color, var(--body-content-color));
    --h3-font-size-mobile: var(--imported_h3Mobile_font_size, 16px);
    --h3-line-height-mobile: var(--imported_h3Mobile_line_height, 24px);
    --h3-text-transform-mobile: var(--imported_h1_text_transform, none);

    // h4 desktop
    --h4-color: var(--imported_h4_color, var(--body-content-color));
    --h4-font-size: var(--imported_h4_font_size, 18px);
    --h4-font-weight: var(--imported_h4_font_weight, 400);
    --h4-line-height: var(--imported_h4_line_height, 24px);
    --h4-text-transform: var(--imported_h1_text_transform, none);

    // paragraph
    --paragraph-color: var(--imported_paragraph_color, var(--body-content-color));
    --paragraph-font-size: var(--imported_paragraph_font_size, 16px);
    --paragraph-line-height: var(--imported_paragraph_line_height, 24px);
    --h4-text-transform-mobile: var(--imported_h1_text_transform, none);

    // paragraph mobile
    --paragraph-color-mobile: var(--imported_paragraphMobile_color, var(--body-content-color));
    --paragraph-font-size-mobile: var(--imported_paragraph_font_size, 16px);
    --paragraph-line-height-mobile: var(--imported_paragraph_line_height, 24px);

    // caption mobile
    --caption-line-height-mobile: var(--imported_captionMobile_line_height, 20px);
}

body {
    font-family: $font-Moller;
    color: var(--body-content-color);
}

strong {
    font-weight: 500;
}

h4 {
    color: var(--h4-color);
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);
    line-height: var(--h4-line-height);
}

main {
    @include mobile {
        margin-block-start: calc(var(--header-total-height) + 40px);
    }
}

[data-content-type="text"],
[data-content-type="html"] {
    li {
        list-style-type: unset;
    }
}
