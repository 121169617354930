.privacy-cookies-policy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 700px;
    width: 100%;

    h1 {
        margin-block-end: 24px;
        font-size: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 68px;
        max-width: 600px;
        margin: 0 auto 24px;
    }

    .privacy-heading-block, .cookies-heading-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-block-end: 47px;

        p {
            /* stylelint-disable-next-line */
            font-size: 24px !important;
            color: #575b63;
            text-align: center;
            line-height: 32px;
            font-weight: 500;
            margin-block-end: 24px;

            strong {
                font-weight: 700;
                line-height: 32px;
            }
        }
    }

    .privacy-policy, .cookie-policy {
        p, li {
            font-size: var(--h4-font-size);
            color: #575b63;
            margin: 0 0 24px;
        }

        .privacy-section-title, li {
            font-weight: 500;
        }

        li {
            strong {
                /* stylelint-disable-next-line */
                color: #575b63 !important;
            }
        }
    }

    .cookie-policy {
        .privacy-section-title {
            text-transform: uppercase;
        }

        .service-provider {
            margin-block-end: 77px;

            a {
                border-bottom: 2px solid #0021f3;
            }
        }
    }

    ul {
        margin: 0 0 30px 0;

        li {
            position: relative;
            margin: 0 0 6px;
        }

        li::before {
            content: '';
            position: absolute;
            margin-block-start: 8px;
            width: 7px;
            height: 7px;
            background: #0021f3;
            border-radius: 50%;
            /* stylelint-disable-next-line */
            padding: 0 !important;
        }
    }

    table {
        td {
            vertical-align: middle;
        }
    }
}
