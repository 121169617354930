/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --breadcrumbs-active-color: var(--body-content-color);
    --breadcrumbs-color: var(--body-content-secondary-color);
}

.Breadcrumbs {
    &-List {
        padding: 24px 0 16px;
    }

    @include mobile {
        display: block;
    }
}
