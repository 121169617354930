/* stylelint-disable declaration-no-important */

.leasing {
    grid-template-rows: 379px auto 114px 0;
    grid-template-columns: minmax(642px,50%) minmax(770px,50%) !important;

    &.lv {
        grid-template-rows: 379px auto 114px 0;
    }

    &.lt {
        grid-template-rows: 379px auto 114px 0;
    }

    &.ee {
        grid-template-rows: 379px auto 114px 0;
    }

    &.lv_ru, &.lt_ru, &.ee_ru {
        grid-template-rows: 379px auto 114px 0;
    }

    &.lv_en, &.lt_en, &.ee_en {
        grid-template-rows: 379px auto 114px 0;
    }

    @include tablet {
        gap: 0px !important;
    }

    @include mobile {
        display: block;
        gap: 0px !important;
    }

    .leasing-service-block {
        grid-column: 1;
        grid-row: 1 / span 2;
        height: fit-content;
        z-index: 1;

        @include tablet {
            margin-block-start: 16px;
            margin-block-end: 32px;
        }

        @include mobile {
            margin-block-start: 16px;
            margin-block-end: 32px;
        }
    }

    .insurance-form {
        grid-column: 1;
        grid-row: 2 / span 1;
    }

    .leasing-calculator-iframe-block {
        grid-column: 2;
        grid-row: 1 / span 2;

        @media (max-width: 1023px) {
            margin-inline-start: 0;
        }

        iframe {
            ::-webkit-scrollbar {
                width: 6px;
            }

            ::-webkit-scrollbar-thumb {
                background: var(--body-content-secondary-color);
            }

            ::-webkit-scrollbar-track {
                background: var(--background-stroke-color);
            }
        }
    }

    .get-offer-button-block {
        grid-column: 2;
        grid-row: 3 / span 1;
        max-width: 602px;
        margin-inline: 20px;

        @include tablet {
            margin-block-start: 16px;
            margin-block-end: 32px;
        }

        @include mobile {
            margin-block-start: 16px;
            margin-block-end: 32px;
        }

        @media (max-width: 674px) {
            margin-inline: 0;
        }
    }
}

/* stylelint-enable declaration-no-important */
