/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-categories-border: 1px solid lightgray;
    --blog-category-chewron-border: 1px solid gray;
    --blog-category-link-font-size: 14px;
    --blog-category-chewron-size: 10px;
    --blog-category-filter-color: #{$black};
    --blog-category-filter-backround-active-color: #{$black};
    --blog-category-filter-backround-color: #{$white};
}

.BlogCategories {
    &-Category {
        display: inline-block;
        height: 35px;
        text-align: center;
        margin-right: 25px;

        h3 {
            display: inline-block;
            font-size: 18px;
            font-weight: 600;
            margin: auto;
            padding-top: 12px;
            color: var(--color-blue);
        }

        a {
            &:hover {
                h3 {
                    @include desktop {
                        border-bottom: 2px solid var(--link-hover);
                        color: var(--color-dark-blue);
                    }
                }
            }
        }

        &_isSelected {
            h3 {
                color: var(--color-dark-blue);
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 4px;
            }

            a {
                &:hover {
                    h3 {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    &-Title {
        text-align: left;
        display: inline-block;
        text-transform: capitalize;

        @include mobile {
            margin-bottom: 5px;
        }

        @include desktop {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: initial;
            margin-top: 20px;
        }
    }

    &-Categories {
        @include mobile {
            overflow-x: auto;
            white-space: nowrap;
            grid-column-gap: 10px;
            text-align: left;
        }

        @include desktop {
            display: inline-block;
        }
    }
}
