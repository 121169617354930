/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.StoreSwitcher {
    &-StoreList {
        inset-inline: 0;
    }
}

.StoreItem {
    &-Item {
        display: flex;
        align-items: center;
        font-weight: 400;
        padding-inline: 6px;
        padding-block: 4px;

        img {
            height: 24px;
            width: 24px;
            margin-inline-end: 4px;
        }
    }
}
