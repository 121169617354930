@font-face { // Regular
    font-family: 'Moller';
    src: url(../fonts/Moller-Regular.woff2) format('woff2'),
        url(../fonts/Moller-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face { // Medium
    font-family: 'Moller';
    src: url(../fonts/Moller-Medium.woff2) format('woff2'),
        url(../fonts/Moller-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face { // Bold
    font-family: 'Moller';
    src: url(../fonts/Moller-Bold.woff2) format('woff2'),
        url(../fonts/Moller-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}
