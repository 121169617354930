/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

$overlay-light: rgba(0, 0, 0, 0.35);
$overlay-dark: rgba(0, 0, 0, 0.55);
$overlay-darker: rgba(0, 0, 0, 0.80);

$career-height: 144px;
$card-height: 424px;

.CmsPage {
    ul {
        margin-inline-start: 0;
    }
}

.HomePage {
    &-Section {
        margin-block-end: 80px;

        @include desktop {
            margin-block-end: 104px;
        }

        .BlogRecentPosts-Button:not(.Button_isHollow) {
            margin-block-start: 56px;
            width: 252px;
        }
    }

    &-Heading {
        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 56px;
        }
    }

    &-Overlay {
        &::after {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            inset-block-start: 0;
            z-index: 0;
            background-color: $overlay-dark;
            transition: background-color .25s ease-in-out;
        }

        * {
            position: relative;
            z-index: 10;
        }
    }

    &-Cards {
        @include mobile {
            margin-inline: -16px;
        }

        .pagebuilder-column-group {
            gap: 16px;

            @include desktop {
                gap: 32px;
            }
        }

        .Card {
            height: 0;
            padding: 50% 0;

            @include desktop {
                padding: 15.5% 0;

                &:hover {
                    &::after {
                        background-color: $overlay-darker;
                    }

                    .Card {
                        &-Heading,
                        &-Button {
                            transform: translateY(-20px)
                        }
                    }
                }
            }

            @include wide-desktop {
                height: $card-height;
                padding: 0;
            }

            &-Button {
                transition-property: transform, background, color, border;
                transition-duration: 0.25s;
                transition-timing-function: ease-in-out;
            }

            &-Heading {
                color: var(--secondary-light-color);
                margin-block-end: 16px;
                transition: transform 0.25s ease-in-out;

                @include desktop {
                    margin-block-end: 32px;
                }
            }
        }
    }

    &-Career {
        &::after {
            background-color: $overlay-light;
        }

        .Career {
            &-Heading {
                color: var(--secondary-light-color);

                @include mobile {
                    text-align: center;
                }
            }

            &-Banner {
                @include mobile {
                    padding: 40px 0 24px;
                }

                &::after {
                    background-color: $overlay-light;
                }

                .Career-Heading {
                    position: relative;
                }
            }

            &-Jobs {
                z-index: 20;

                @include mobile {
                    padding: 24px 0 40px;
                }

                @include desktop {
                    min-height: $career-height;
                    padding: 0;
                }

                .Career-Heading {
                    margin-block-end: 24px;
                }
            }

            &-Button {
                a.pagebuilder-button-primary {
                    border-radius: 24px;
                    background-color: var(--primary-base-color);
                    border-color: var(--primary-base-color);


                    &:not([disabled]):hover,
                    &:hover {
                        border-radius: 24px;
                    }
                }
            }
        }
    }
}
