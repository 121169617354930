/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --navigation-tabs-height: calc(40px + env(safe-area-inset-bottom));
}

.NavigationTabs {
    border-block-start: none;
    /* stylelint-disable-next-line */
    top: 84px;
    bottom: initial;

    .hideOnScroll & {
        transform: translateY(calc(-100% - 84px));
    }
}
