$text-color: #000;
$text-secondary-color: #666;
$text-description-color: #2f2f2f;
$text-disabled-color: #bbb;
$error-color: #ff3939;
$attention-color: #f0b901;
$hover-color: #66f;
$background-white-color: #fff;
$background-hover-color: #f5f5f5;
$background-stroke-color: #e8e7e7;

:root {
    --color-black: #000;
    --body-content-color: #{$text-color};
    --body-content-secondary-color: #{$text-secondary-color};
    --body-description-color: #{$text-description-color};
    --body-content-disabled-color: #{$text-disabled-color};
    --primary-error-color: #{$error-color};
    --primary-attention-color: #{$attention-color};
    --background-white-color: #{$background-white-color};
    --background-hover-color: #{$background-hover-color};
    --background-stroke-color: #{$background-stroke-color};
    --primary-hover-color: #{$hover-color};
}
