/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.SearchField {
    input {
        height: 52px;
    }

    &-SearchIcon {
        inset-inline-start: 15px;
    }

    &-Input {
        padding-inline-start: 60px;
        line-height: 24px;

        &_isActive {
            padding-inline-start: 20px;
        }

        @include mobile {
            padding-block-start: 12px;
            padding-block-end: 12px;
        }
    }

    &-CloseIcon {
        & .CloseIcon {
            fill: var(--body-content-secondary-color);
        }
    }
}
