.book-the-service {
    &-ru {
        .Field {
            &-Label {
                font-weight: 700;
            }
        }
    }

    .RenderWhenVisible {
        grid-column: 2;
        grid-row: 1 / span 3;

        .FlexibleFormsWidget {
            margin: 0;
            padding: 32px;
            border: 1px solid var(--background-stroke-color);

            @include mobile {
                padding: 24px;
            }

            h2 {
                margin-block: 0 32px;
                text-align: start;

                @include mobile {
                    margin-block-end: 24px;
                    font-size: 24px;
                    line-height: 32px;
                }
            }

            h3,
            p,
            hr {
                display: none;
            }

            textarea {
                min-height: 104px;
            }

            form {
                display: flex;
                flex-direction: column;

                > div > div {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 32px;
                }

                > div:nth-of-type(1) > div {
                    @include desktop {
                        grid-template-columns: 1fr 1fr;
                    }

                    .Field {
                        &-Wrapper {
                            &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6) {
                                @include desktop {
                                    grid-column: 1 / 3;
                                }
                            }
                        }
                    }
                }

                .Field {
                    &-Wrapper {
                        margin-block: 0 16px;

                        @include mobile {
                            margin-block-end: 24px;
                        }

                        .Field {
                            margin-block-start: 0;

                            input,
                            textarea {
                                width: 100%;
                            }
                        }
                    }
                }

                .FieldSelect {
                    &-Options {
                        &_isExpanded {
                            margin: 0;
                        }
                    }
                }

                .FieldGroup {
                    &-Wrapper {
                        margin-block: 0 16px;

                        .FieldGroup {
                            flex-direction: column;
                            gap: 16px;

                            .Field {
                                &-Wrapper {
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .FlexibleForms {
                    &-required {
                        label::after {
                            content: " *";
                            color: var(--primary-error-color);
                        }
                    }

                    &-Submit {
                        @include desktop {
                            width: 152px;
                            align-self: flex-end
                        }
                    }
                }
            }
        }
    }
}
