/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --menu-item-height: 72px;
    --menu-total-height: 72px;
}

.Menu {
    &-MenuWrapper {
        position: unset;
        margin-block-start: 0;

        @include desktop {
            &::before {
                content: "";
                position: absolute;
                inset-inline-start: 0;
                width: 100%;
                height: 1px;
                background-color: var(--background-stroke-color);
            }
        }
    }

    &-MainCategories {
        padding-inline: 52px;
        width: 100%;
        height: var(--menu-item-height);

        @include tablet {
            padding-inline: 0;
        }

        @include mobile {
            padding-inline: 0;
        }

        #CurrencySwitcherList {
            padding: 16px 0 8px;
        }
    }

    &-ItemList {
        &_type_main {
            margin-block-start: 32px;

            @include desktop {
                margin-block-start: 0;
            }
        }
    }

    &-Item {
        flex-grow: 1;
        margin-block-end: 24px;

        &:not(:first-of-type) {
            margin-inline-start: 0;
        }

        @include desktop {
            width: 100%;
            height: var(--menu-item-height);
            margin-block-end: 0;

            .Menu-ItemCaption {
                text-align: start;
            }
        }

        @include narrow-desktop {
            width: auto;
        }

        @include tablet {
            width: auto;
        }

        &:hover {
            @include desktop {
                background-color: var(--background-hover-color);
            }
        }
    }

    &-ItemCaption {
        &:hover {
            color: var(--body-content-color);
        }

        &_type_main {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            margin-block-end: 0;
            text-transform: none;
            padding: 0;
            color: var(--body-content-color);

            @include desktop {
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: 100%;
                padding: 12px;
            }

            @include tablet {
                padding: 8px;
            }

            @include mobile {
                height: 24px;
            }
        }
    }

    &-CompareLinkWrapper {
        padding: 8px 0;
    }

    @include mobile {
        &Page {
            overflow-y: scroll;

            .Header-Links {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 16px;
                margin-block-start: 32px;

                a {
                    flex: 1;
                    text-align: center;
                }
            }
        }
    }

    @include desktop {
        &-ExpandedState {
            display: block;
            position: relative;
            margin-inline-start: 24px;
            margin-block-start: 5px;
        }

        &-Sub {
            &ItemWrapper {
                padding: 0;

                &:hover {
                    background-color: var(--background-hover-color);
                }

                > .Menu-Link {
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 0 36px;

                    .Menu-ItemCaption_isSecondLevel {
                        font-weight: 400;
                    }
                }
            }

            &CatLink {
                height: 100%;

                &:hover {
                    .Menu-SubMenu {
                        display: block;
                    }
                }
            }

            &Menu {
                margin-block-end: 0;
                position: absolute;
                background: white;
                inset-block-start: calc(100% + 1px);
                inset-inline: 0;
                inset-inline-end: 0;
                display: none;
                box-shadow: 0 10px 10px rgba(51, 51, 51, 0.7);

                > .Menu-ItemList {
                    columns: 1;
                }
            }
        }
    }
}
