/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --disabled-color: #9b9bff;
}

.StyleGuidePage {
    &-ActiveLink {
        border-block-end: none;
        color: var(--link-hover-color);
        text-decoration: var(--link-hover-underline);
    }

    &-Heading {
        background: none;
        border-block-end: 1px solid var(--disabled-color);
        color: var(--body-content-color);
        font-size: 24px;
        line-height: 32px;
        margin-block-start: 60px;
        padding: 12px 16px;
    }

    &-MobileTextStyles {
        h1 {
            line-height: 32px;
        }

        h2,
        h3 {
            line-height: 24px;
        }
    }

    &-SubHeading {
        border: none;
        color: var(--body-content-secondary-color);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-block-start: 32px;
        padding-block-end: 0;
    }

    &-Colors {
        display: flex;
        gap: 24px;
        flex-direction: column;

        > div {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @include desktop {
                flex-direction: row;
            }
        }
    }

    &-Color {
        width: 115px;
        height: 112px;
        padding-block: 16px;
        padding-inline: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
            color: var(--color-white);
            margin-block-end: 0;
            display: inline-block;
            font-size: 16px;
            line-height: 24px;
        }

        &#hover {
            background-color: var(--primary-hover-color);
        }

        &#primaryText {
            background-color: var(--body-content-color);
        }

        &#secondaryText {
            background-color: var(--body-content-secondary-color);
        }

        &#disabledText {
            background-color: var(--body-content-disabled-color);
        }

        &#backgroundWhite {
            background-color: var(--background-white-color);
        }

        &#BackgroundHover {
            background-color: var(--background-hover-color);
        }

        &#backgroundStroke {
            background-color: var(--background-stroke-color);
        }

        &#attention {
            background-color: var(--primary-attention-color);

            p {
                color: var(--color-black);
            }
        }
    }

    &-BackgroundColors {
        p {
            color: var(--color-black);
        }
    }

    &-ColorLabel {
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        margin-inline-start: 0;
        font-weight: normal;
    }

    &-WideDesktopContentWrapper {
        border: 1px solid var(--primary-base-color);
        padding-inline: 52px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-DesktopContentWrapper {
        max-width: 1024px;
        padding-inline: 52px;
    }

    &-TabletContentWrapper {
        border: 1px solid var(--primary-base-color);
        max-width: 768px;
        padding-inline: 32px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-MobileContentWrapper {
        max-width: 414px;
        padding-inline: 16px;
    }

    &-SmallMobileContentWrapper {
        border: 1px solid var(--primary-base-color);
        max-width: 320px;
        padding-inline: 16px;
        height: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &-HoveredInput#hoveredCheckbox~.input-control, &-HoveredInput#hoveredRadio~.input-control {
        @include desktop {
            border-color: var(--primary-hover-color);

            &::after {
                @include desktop {
                    --checkmark-color: var(--color-white);
                }
            }
        }
    }

    &-Select, &-Text {
        &_isValid {
            border: 1px solid var(--body-content-secondary-color);
        }
    }
}
