/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --newsletter-subscription-placeholder-color: var(--input-border-color, var(--secondary-dark-color));
}

.NewsletterSubscription {
    .FieldForm {
        &-Fieldset {
            .Field {
                input {
                    height: 52px;
                    margin-block-end: 8px;
                    margin-block-start: 16px;
                    padding: 14px 20px;

                    @include desktop {
                        width: 255px;
                        margin-block-end: 0;
                    }

                    @include mobile {
                        width: 288px;
                        margin-block-end: 8px;
                    }
                }

                &-Wrapper {
                    &_type_email {
                        width: 100%;
                        margin-block-end: 8px;

                        @include desktop {
                            width: 255px;
                            margin-block-end: 0;
                        }

                        input[type="email"] {
                            width: 100%;
                            height: unset;
                            margin-block-end: 0;
                            margin-block-start: 0;
                            border-color: var(--color-dark-gray);
                            padding: 10px 20px;

                            &::placeholder {
                                opacity: 1;
                            }
                        }

                        &.Field_hasError input[type="email"] {
                            border: 1px solid var(--primary-error-color);
                        }
                    }
                }

                &-CheckboxLabel {
                    width: 100%;

                    input[type="checkbox"] {
                        margin: 0;
                        padding: 0;
                        height: 100%;

                        &:checked + .input-control {
                            border: 1px solid var(--checkbox-border-color);
                        }
                    }

                    .input-control {
                        border: 1px solid var(--body-content-secondary-color);
                    }
                }
            }
        }

        &-Body {
            @include desktop {
                display: flex;
            }

            .Button {
                font-size: 16px;
                line-height: 32px;
                margin: 0;
                color: var(--color-karaka);
                text-transform: capitalize;
                font-weight: 500;
                border-radius: 24px;
                padding: 0 32px;
                width: unset;
                height: unset;

                @include mobile {
                    margin-block-start: 0;
                }
            }
        }

        &-EmailGroup {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-block: 16px;
            gap: 8px;

            @include desktop {
                margin-block-end: 24px;
                gap: 16px;
            }
        }
    }

    &-NewsletterCheckbox {
        padding-block-end: 8px;
    }
}
