/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
$icon-arrow-black: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16"><path stroke="%23393748" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h14M8 1l7 7-7 7"/></svg>');

.PostsListing {
    margin-block-start: 0;

    .ContentWrapper {
        margin: auto;
        padding: 0 20px 30px;

        @include desktop {
            padding: 0 30px 10px;
        }
    }

    &-Header {
        display: flex;
        justify-content: space-between;
        margin-block-end: 24px;

        .BlogSocialLinks-SocialBlock {
            @include mobile {
                display: none;
            }
        }
    }

    &-ColumnWrapper {
        @include mobile {
            flex-wrap: wrap;
        }

        @include desktop {
            bottom: 30px;
        }
    }

    &-Sidebar {
        margin: 0 0 20px;

        @include mobile {
            order: 3;
            width: 100%;
        }

        .BlogSocialLinks-SocialBlock {
            display: flex;
            justify-content: space-between;

            @include desktop {
                display: none;
            }
        }
    }

    &-Grid {
        grid-gap: 20px;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        flex-wrap: wrap;

        @include mobile {
            order: 1;
        }

        @include desktop {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            flex-basis: 70%;
        }

        @include desktop {
            grid-gap: 15px 20px;
        }

        .BlogPostCard {
            @include mobile {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .Pagination {
            @include mobile {
                display: flex;
            }
        }
    }

    &-Title {
        text-align: left;
        text-transform: capitalize;
        font-size: 26px;
        margin: 0;

        @include mobile {
            padding-left: 40px;
            margin: 0;
        }
    }

    &-ToHomepage {
        @include mobile {
            background: $icon-arrow-black no-repeat center;
            position: absolute;
            height: 18px;
            width: 18px;
            transform: rotate(180deg);
            margin-top: 7px;
            z-index: 1;
        }
    }

    nav {
        grid-column: 1 / span 2;
    }

    .CategoryPagination {
        text-align: center;
        flex-basis: 100%;
        margin-bottom: 0;

        @include mobile {
            order: 2;
            width: 100%;
        }
    }

    &-Count {
        color: var(--blog-category-filter-color);
        text-align: right;
        grid-column: 1 / span 2;
    }

    .BlogRss {
        @include desktop {
            margin-bottom: 20px;
        }
    }
}
