$see-all-cars-height: 235px;
$overlay-dark: rgba(0, 0, 0, 0.55);

.SeeAllCars {
    .pagebuilder-column-group {
        @include tablet {
            flex-wrap: wrap;
        }

        @include mobile {
            flex-wrap: wrap;
        }
    }

    .pagebuilder-column {
        @include tablet {
            flex-basis: 100%;
        }

        @include mobile {
            flex-basis: 100%;
        }
    }

    &-Banner {
        height: 0;
        padding: 50% 0;

        @include desktop {
            height: $see-all-cars-height;
            padding: 0;
        }

        * {
            z-index: 10;
        }

        .SeeAllCars-Button {
            @include desktop {
                height: 0;
            }

            @include tablet {
                height: auto;
            }

            .pagebuilder-button-primary {
                @include desktop {
                    display: none;
                }

                @include tablet {
                    display: flex;
                }
            }
        }

        &::after {
            content: "";
            position: absolute;
            z-index: 0;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            background-color: $overlay-dark;
        }
    }

    &-Heading {
        text-transform: none;
        color: var(--secondary-light-color);
    }

    &-SeeAllCars {
        padding-inline: 8px;
        width: 25%;
        height: $see-all-cars-height;
        background-color: var(--primary-base-color);

        @include tablet {
            height: 0;
        }

        @include mobile {
            height: 0;
        }

        * {
            display: block;

            @include tablet {
                display: none;
            }

            @include mobile {
                display: none;
            }
        }
    }

    &-Button {
        .pagebuilder-button-primary {
            @include button;

            margin-block-start: 16px;
            font-size: 16px;
            font-weight: 500;
            text-decoration: none;
            text-transform: none;
            background-color: transparent;
            border-color: var(--secondary-light-color);
            box-shadow: none;
        }
    }
}
