@mixin Button {
    font-size: 17px;
    font-weight: 500;
    text-transform: none;
    line-height: var(--button-line-height);
    height: var(--button-height);
    width: 142px;

    &[disabled] {
        background-color: var(--disabled-button-color);
        color: var(--primary-button-color);
        opacity: 1;
        border-color: var(--disabled-button-color);
    }

    &:not([disabled]):hover {
        box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    }

    &.large {
        width: 252px;
    }

    &_isHollow {
        &[disabled] {
            color: var(--disabled-button-color);
            background-color: inherit;
        }

        &:not([disabled]):hover {
            box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
        }
    }

    &_isOnBanner {
        outline: var(--primary-base-color) 1px solid;
        border: var(--button-on-banner-border) 1px solid;

        &:not([disabled]):hover {
            @include desktop {
                background-color: var(--primary-button-background);
                border: var(--button-on-banner-border) 1px solid;
            }
        }
    }

    &_likeLink {
        font-size: 18px;
        line-height: var(--button-line-height);
        padding: 0;
        background: none;
        color: var(--text-like-button-color);
        height: auto;
        text-decoration: underline;

        &:not([disabled]):hover {
            @include desktop {
                color: var(--text-like-button-hover);
                background-color: var(--hollow-button-background);
            }
        }

        &[disabled] {
            color: var(--disabled-button-color);
            background-color: inherit;
            text-decoration: none;
            font-size: 16px;
        }
    }

    &_likeLink.Button_isHovered:not(.Button[disabled]) {
        color: var(--text-like-button-hover);
    }

    @include mobile {
        height: var(--mobile-button-height);
    }
}
