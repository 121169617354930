/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --blog-post-card-title-weight: 700;
    --blog-post-card-title-size: 16px;
    --blog-post-card-description-height: 100px;
    --blog-post-card-border: 1px solid lightgray;
    --blog-card-title-background-color: #{$white};
}

.BlogPostCard {
    font-size: 12px;
    text-align: left;
    height: 100%;
    display: flex;
    width: 100%;

    &_isLoading {
        padding: 80% 0 0;
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        box-shadow: var(--attraction-shadow);
    }

    &_isCompactOnMobile {
        @include mobile {
            display: flex;
            padding: 10px 0;
            border-top: var(--blog-post-card-border);

            &:last-child {
                border-bottom: var(--blog-post-card-border);
            }
        }

        &-Title {
            @include mobile {
                padding-top: 0;
            }
        }

        &-DescriptionWrapper {
            @include mobile {
                display: none;
            }
        }

        &-Date {
            @include mobile {
                position: absolute;
                bottom: 0;
                display: block;
            }
        }

        &-ContentWrapper {
            @include mobile {
                display: flex;
                width: 100%;
            }
        }

        &-Details {
            @include mobile {
                display: block;
                flex-basis: 60%;
                padding-left: 10px;
            }
        }
    }

    &-Details {
        background-color: var(--secondary-base-color);
        border-radius:  0 0 16px 16px;
        padding: 20px;
        flex-grow: 1;
    }

    &-Title {
        border-radius: 0 10px 0 10px;
        font-size: var(--blog-post-card-title-size);
        font-weight: var(--blog-post-card-title-weight);
        margin-bottom: 15px;
    }

    &-DescriptionWrapper {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }

    &-TagWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 12px;
        font-weight: normal;
        gap: 15px;
    }

    &-TagTitle {
        background-color: var(--body-content-color);
        color: var(--background-white-color);
        font-size: 14px;
        line-height: 20px;
        padding: 4px 8px;
        overflow-x: hidden;
        max-width: 100px;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-height: 28px;

        &:hover {
            @include desktop {
                color: var(--color-dark-blue);
            }
        }
    }

    &-Date {
        display: none;
    }

    &-Image {
        height: 350px;
        padding-bottom: 0;

        > img {
            position: relative;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
        }
    }

    &-ImageWrapper {
        flex-basis: 40%;
        display: flex;
    }

    &-Details {
        padding: 20px;
        padding-right: 25px;
    }

    &-ContentWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}
