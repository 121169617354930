// This class is for general use with CMS blocks

.moller-block {
    h1, h2, h3, h4, h5, h6 {
        text-align: center;
        margin-block-start: 30px;
        margin-block-end: 20px;
    }

    img {
        margin-block-start: 20px;
        margin-block-end: 20px;
    }
}
