.insurance-form {
    max-width: 642px;
    width: 100%;
    padding: 32px;
    border: 1px solid var(--background-stroke-color);
    margin-block-end: 32px;
    height: fit-content;

    .widget {
        /* stylelint-disable-next-line */
        overflow-x: visible !important;
    }

    @include tablet {
        padding: 16px;
    }

    @include mobile {
        padding: 16px;
    }

    &-ru {
        .Field {
            &-Label {
                font-weight: 700;
            }
        }
    }

    .FlexibleFormsWidget {
        margin: 0;

        > form {
            > h2 {
                margin-block-start: 0;
                margin-block-end: 32px;
                font-size: 24px;
                line-height: 32px;
                text-align: start;

                @include desktop {
                    font-size: 32px;
                    line-height: 36px;
                }
            }

            > p {
                margin-block-end: 52px;

                @include tablet {
                    margin-block-end: 32px;
                }

                @include mobile {
                    margin-block-end: 32px;
                }
            }
        }

        .Field {
            &-Wrapper {
                width: 273px;
                margin-block-start: 0;
                margin-block-end: 16px;

                @include mobile {
                    width: 100%;
                }

                &:nth-child(3) {
                    width: 100%;
                }

                &_type {
                    &_email {
                        width: 100%;
                    }

                    &_select {
                        width: 100%;

                        .FieldSelect-Options {
                            margin-inline-start: 0px;
                        }
                    }

                    &_checkbox {
                        width: 100%;
                    }
                }
            }

            &-Label {
                line-height: 20px;
            }

            &-Label::after {
                content: " *";
                color: #FF3939;
            }
        }

        .FieldGroup {
            flex-direction: column;
            gap: 14px;

            &-Wrapper {
                margin-block: 0 16px;
            }
        }

        input {
            width: 100%;
        }
    }

    hr, h3 {
        display: none;
    }

    .insurance-form-title {
        margin-block-start: 0;
        margin-block-end: 32px;

        strong {
            font-weight: 700;
        }

        @include mobile {
            font-size: 24px;
        }
    }

    .insurance-form-subtitle {
        /* stylelint-disable-next-line */
        display: inline-block !important;
        margin-block-start: 0;
        margin-block-end: 16px;

        strong {
            font-weight: 700;
        }

        @include tablet {
            text-align: start;
        }

        @include mobile {
            text-align: start;
            font-size: 20px;
        }
    }

    .insurance-form-text {
        /* stylelint-disable-next-line */
        display: inline-block !important;
        margin-block-end: 0;
    }

    form {
        display: flex;
        flex-direction: column;

        p:last-of-type {
            display: none;
        }

        > div > div {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }

    .Field {
        margin-block-start: 0;

        input {
            width: 100%;
        }
    }

    button {
        width: 252px;
        align-self: flex-end;

        @include mobile {
            margin-block-start: 24px;
        }
    }
}
