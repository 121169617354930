.leasing-form {
    &-ru {
        .Field {
            &-Label {
                font-weight: 700;
            }
        }
    }

    p ol li {
        list-style: decimal;
    }

    p ul li {
        list-style: disc;
    }

    .RenderWhenVisible {
        grid-column: 2;
        grid-row: 1 / span 3;

        .FlexibleFormsWidget {
            margin: 0;
            padding: 32px;
            border: 1px solid var(--background-stroke-color);

            @include mobile {
                padding: 0px;
                border: none;
            }

            @include tablet {
                padding: 0px;
                border: none;
            }

            h2 {
                margin-block: 0 32px;
                text-align: start;

                @include mobile {
                    margin-block-end: 24px;
                }
            }

            h3,
            hr {
                display: none;
            }

            p {
                margin: 0;
            }

            textarea {
                min-height: 104px;
            }

            form {
                > div > div {
                    display: grid;
                    grid-template-columns: 1fr;
                    column-gap: 32px;

                    @include mobile {
                        display: block;
                    }

                    @include tablet {
                        display: block;
                    }
                }

                > div:nth-of-type(1) > div {
                    @include desktop {
                        grid-template-columns: 1fr 1fr;
                    }
                }

                .Field {
                    &-Wrapper {
                        margin-block: 0 16px;

                        @include mobile {
                            margin-block-end: 24px;
                        }

                        &_type_email, &_type_textarea, &_type_file, &_type_select, &_type_checkbox {
                            grid-column: 1 / 3;
                        }

                        &:nth-child(3) {
                            grid-column: span 2;
                        }

                        &_type_file {
                            .Field {
                                > label {
                                    margin-block-start: 26px;
                                }
                            }
                        }

                        &_type_checkbox {
                            .Field {
                                &-CheckboxLabel {
                                    display: flex;

                                    span {
                                        flex: 1;
                                    }
                                }
                            }
                        }

                        .Field {
                            margin-block-start: 0;

                            input,
                            textarea {
                                width: 100%;
                            }
                        }
                    }
                }

                .FieldSelect {
                    &-Options {
                        &_isExpanded {
                            margin: 0;
                        }
                    }
                }

                .FieldGroup {
                    flex-direction: column;
                    gap: 16px;

                    &-Wrapper {
                        grid-column: 1 / 3;
                        margin-block: 0 16px;

                        .Field {
                            &-Wrapper {
                                margin: 0;
                            }
                        }
                    }
                }

                .FlexibleForms {
                    &-required {
                        .Field {
                            &-Label::after {
                                content: " *";
                                color: var(--primary-error-color);
                            }
                        }
                    }

                    &-Submit {
                        justify-self: flex-end;

                        @include desktop {
                            grid-column: 2;
                            width: 152px;
                        }

                        @include tablet {
                            grid-column: auto;
                        }

                        @include mobile {
                            margin-block-start: 24px;
                        }
                    }
                }
            }
        }

        .Form {
            display: block;

            @include desktop {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-template-rows: auto 1fr;
                column-gap: 116px;
            }

            @include tablet {
                grid-template-columns: auto;
            }

            & > h2 {
                margin-block-end: 0px;
                font-weight: 700;

                @include mobile {
                    font-size: 24px;
                }
            }

            & > div {
                @include desktop {
                    grid-column: 2 / 3;
                    grid-row: 1 / span 3;
                }

                @include tablet {
                    grid-column: auto;
                    grid-row: auto;
                }
            }

            & > p h2 {
                margin-block-start: 8px;
                margin-block-end: 24px;
                font-size: 18px;

                & strong {
                    font-weight: 700;
                }
            }

            & > p > *:not(h2):first-child {
                margin-block-start: 24px;
            }
        }
    }
}
