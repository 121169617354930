/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

:root {
    --author-text-color: #{$black};
}

.BlogComment {
    &-Wrapper {
        background-color: var(--secondary-base-color);
        border-radius: 16px;
        margin-bottom: 15px;
        padding: 20px;
    }

    &-Author {
        font-weight: bold;
        font-size: 14px;
        color: var(--author-text-color);
        margin-bottom: unset;
    }

    &-Date {
        font-size: 14px;
        font-weight: normal;
        color: var(--secondary-dark-color);
    }

    &-ReplyButton {
        margin-left: auto;
        line-height: 1;
    }

    &-Content {
        margin: 1rem auto;
        font-size: 14px;

        a {
            color: var(--link-hover);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-Replies {
        width: 90%;
        flex-grow: 1;
        margin-left: auto;
        padding-bottom: 15px;

        .BlogComment-Wrapper {
            margin-bottom: 0;
        }
    }
}
