/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.BlogRelatedPosts {
    &-Title {
        text-align: left;

        @include mobile {
            padding-left: 20px;
        }

        @include desktop {
            margin-left: 0;
            margin-top: 0;
        }
    }

    &-Wrapper {
        @include desktop {
            background-color: var(--secondary-base-color);
            border-radius: 16px;
            padding: 20px;
            margin-top: 10px;
        }

        button {
            @include desktop {
                pointer-events: none;
            }
        }
    }

    &-Button {
        @include mobile {
            background-color: var(--secondary-base-color);
            border-radius: 16px;
            font-size: 16px;
            width: 100%;
            margin-bottom: 20px;
        }

        &:after {
            @include mobile {
                border-color: var(--color-black);
                border-width: 0 3px 3px 0;
                content: '';
                height: 10px;
                right: 26px;
                transform: rotate(-45deg);
                width: 9px;
                display: block;
                pointer-events: none;
                position: absolute;
                bottom: 0;
                top: 4px;
                margin: auto;
                border-style: solid;
            }
        }

        &_isExpanded {
            @include mobile {
                background-color: var(--color-blue);
                color: var(--color-white);
            }

            &:after {
                border-color: var(--color-white);
                transform: translateY(-25%) rotate(45deg);
            }
        }
    }

    &-PostsWrapper {
        @include mobile {
            display: none;
        }

        &_isExpanded {
            background-color: var(--secondary-base-color);
            border-radius: 16px;
            display: block;
            padding: 20px 20px 0;

            @include mobile {
                margin-bottom: 25px;
                padding-top: 0;
            }
        }

        .BlogPostCard {
            border: none;
            border-radius: 16px;

            &-ContentWrapper {
                flex-direction: row;

                @media (max-width: 1100px) {
                    flex-direction: column;
                }

                @include mobile {
                    flex-direction: row;
                }
            }

            &-Image {
                height: 100px;
                flex-basis: 40%;
                border-radius: 16px;
                margin: 10px 0;
            }

            &-ImageWrapper {
                display: block;
            }

            &-Title {
                margin-top: 5px;
                text-align: left;
                text-transform: capitalize;

                @include desktop {
                    text-align: left;
                }
            }

            &-Details {
                bottom: 0;
                display: block;
                margin: auto;
                flex-basis: 60%;
                padding: 10px 0 10px 20px;

                @media (max-width: 1100px) {
                    padding: 0;
                }

                @include mobile {
                    padding: 10px 0 10px 20px;
                }
            }

            &-DescriptionWrapper {
                display: none;
            }
        }
    }
}
