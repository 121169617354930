.get-offer-button-block {
    max-width: 642px;
    width: 100%;
    padding-block: 32px;
    border: 1px solid var(--background-stroke-color);
    display: flex;
    justify-content: center;

    .widget {
        /* stylelint-disable-next-line */
        overflow-x: unset !important;
    }

    .get-offer-button {
        width: 252px;
        /* stylelint-disable-next-line */
        padding: 0 !important;

        @include mobile {
            width: 286px;
        }

        @media (max-width: 320px) {
            width: 192px;
        }

        a {
            color: $background-white-color;
            font-size: var(--paragraph-font-size);
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}
