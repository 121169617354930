.Body_and_Paint {
    max-width: 642px;
    width: 100%;
    padding: 32px;
    border: 1px solid var(--background-stroke-color);

    @include tablet {
        padding: 16px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        margin-block-end: 0;
    }

    @include mobile {
        padding: 16px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        margin-block-end: 0;
    }

    h1 {
        font-weight: 700;
        margin-block-end: 44px;

        @include mobile {
            margin-block-end: 32px;
        }
    }

    ul {
        /* stylelint-disable-next-line */
        margin-inline-start: 0 !important;
        margin-block-end: 44px;

        @include mobile {
            margin-block-end: 32px;
        }

        li {
            display: flex;
            align-items: center;
            margin-block-end: 28px;
        }
    }

    &-Quality-Icon, &-Service-Icon, &-Shield-Icon {
        margin-inline-end: 16px;
        max-width: 40px;
        width: 100%;
        height: 40px;
    }

    &-Quality-Icon {
        background: url(../../style/icons/ServicePage/quality-icon.svg) no-repeat;
    }

    &-Service-Icon {
        background: url(../../style/icons/ServicePage/users-icon.svg) no-repeat;
    }

    &-Shield-Icon {
        background: url(../../style/icons/ServicePage/shield-icon.svg) no-repeat;
    }

    &-Quality-Text, &-Service-Text, &-Shield-Text {
        /* stylelint-disable-next-line */
        margin-block-end: 0 !important;
        font-weight: 700;
        font-size: var(--h2-font-size);

        @include mobile {
            font-size: var(--h2-font-size-mobile);
        }
    }
}
