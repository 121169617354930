/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

.BlogCommentsList {
    &-Title {
        margin-bottom: 20px;
    }

    &-ButtonWrapper {
        margin: 15px 0 30px;
        display: flex;

        @include mobile {
            flex-direction: column;
        }

        @include desktop {
            justify-content: center;
            margin: 15px 0 35px;
        }

        .Button {
            &:not(:first-of-type) {
                @include mobile {
                    margin-top: 20px;
                }
            }
        }
    }

    &-ShowAllButton {
        @include desktop {
            margin-left: 15px;
        }

        @include mobile {
            margin-top: 15px;
        }
    }
}
