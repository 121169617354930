/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.Breadcrumb {
    $crumb-padding: 15px;

    padding-inline-end: $crumb-padding;
    margin-block-end: 0;

    &-Link {
        font-weight: bold;
        line-height: 20px;
    }

    .ChevronIcon {
        margin-inline-start: $crumb-padding;
    }

    &:last-child {
        margin-block-end: 0;

        :link {
            color: var(--breadcrumbs-active-color);
        }
    }
}
