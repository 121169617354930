/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --footer-nav-height: 40px;
}

.Footer {
    &-ColumnTitle {
        font-size: 16px;

        @include desktop {
            margin-block-end: 8px;
        }
    }

    &-ColumnItem {
        font-size: 16px;
        line-height: 24px;

        @include desktop {
            margin-block-end: 16px;
        }

        &:hover {
            @include desktop {
                text-decoration: none;
                -webkit-text-fill-color: black;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: black;
            }
        }
    }

    &-Content {
        background-color: var(--background-hover-color);

        @include desktop {
            background-color: var(--background-white-color);
        }
    }

    &-Columns {
        padding: 40px;

        @include desktop {
            padding: 52px;
        }

        @include mobile {
            padding-inline-start: 63px;
            padding-inline-end: 63px;
            /* stylelint-disable-next-line */
            text-align: left !important;
        }
    }

    &-Column {
        /* stylelint-disable-next-line */
        text-align: left !important;

        &:not(:last-of-type) {
            @include mobile {
                margin-block-end: 32px;
            }
        }
    }

    &-Column_isNewsletter {
        @include mobile {
            width: 288px;
        }
    }

    &-VisaIcon, &-MastercardIcon {
        margin-block-start: 8px;
        margin-block-end: 32px;
        margin-inline-end: 8px;
        width: 35px;
        height: 24px;
        object-fit: contain;

        @include mobile {
            margin-inline-start: 4px;
            margin-inline-end: 4px;
        }
    }

    &-VisaIcon {
        background: url("../../style/icons/Footer/Visa.svg") no-repeat;
    }

    &-MastercardIcon {
        background: url("../../style/icons/Footer/Mastercard.svg") no-repeat;
    }

    &-IconFrame {
        margin-block-start: 8px;
        margin-block-end: 16px;
        margin-inline-end: 16px;

        a {
            width: 24px;
            height: 24px;
        }

        @include mobile {
            margin-inline-start: 8px;
            margin-inline-end: 8px;
            margin-block-end: 32px;
        }
    }

    &-YoutubeIcon, &-InstagramIcon,  &-LinkedinIcon, &-FacebookIcon {
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    &-YoutubeIcon:hover, &-InstagramIcon:hover,  &-LinkedinIcon:hover, &-FacebookIcon:hover {
        opacity: 0.6;
    }

    &-YoutubeIcon {
        @include desktop {
            background: url("../../style/icons/Footer/Youtube.svg") no-repeat;
        }

        @include mobile {
            background: url("../../style/icons/Footer/Youtube_dark.svg") no-repeat;
        }
    }

    &-InstagramIcon {
        @include desktop {
            background: url("../../style/icons/Footer/Instagram.svg") no-repeat;
        }

        @include mobile {
            background: url("../../style/icons/Footer/Instagram_dark.svg") no-repeat;
        }
    }

    &-LinkedinIcon {
        @include desktop {
            background: url("../../style/icons/Footer/Linkedin.svg") no-repeat;
        }

        @include mobile {
            background: url("../../style/icons/Footer/Linkedin_dark.svg") no-repeat;
        }
    }

    &-FacebookIcon {
        @include desktop {
            background: url("../../style/icons/Footer/Facebook.svg") no-repeat;
        }

        @include mobile {
            background: url("../../style/icons/Footer/Facebook_dark.svg") no-repeat;
        }
    }
}
