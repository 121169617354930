/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.CarouselScrollArrow {
    margin-inline: 16px;
    width: 32px;
    height: 32px;
    background-color: var(--background-hover-color);

    &:hover {
        transform: rotate(-180deg);
    }

    &_isNextArrow {
        transform: none;

        &:hover {
            transform: none;
        }
    }

    &_isDisabled {
        opacity: (0.5);

        .ChevronIcon {
            cursor: default;
        }
    }
}
