/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.GdprCookieGroupPopup {
    z-index: 401;

    .Popup {
        &-Content {
            @include mobile {
                overflow-x: hidden;
                overflow-y: hidden;
            }
        }

        &-CloseBtn {
            display: none;
        }
    }

    &-Description {
        font-size: 16px;

        &:last-of-type {
            margin-bottom: 10px;
        }
    }

    &-Table {
        display: block;

        @include mobile {
            overflow-x: auto;
            margin: 0 -14px;
        }

        tbody {
            @include mobile {
                display: block;
                max-height: 40vh;
                overflow-y: auto;
            }

            &::-webkit-scrollbar {
                width: 5px;

                &-thumb {
                    background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                    border-radius: 2px;
                }

                &-track {
                    background-color: var(--gdpr-cookies-group-scrollbar-track-color);
                    box-shadow: none;
                }
            }
        }

        thead {
            @include mobile {
                display: table;
                table-layout: fixed;
                width: 100%;
            }
        }

        th {
            font-size: 14px;

            @include mobile {
                min-width: unset;
            }

            &:first-child {
                @include mobile {
                    width: 25vw;
                    word-break: break-all;
                }
            }

            &:nth-child(2) {
                @include mobile {
                    width: 65vw;
                }
            }

            &:nth-child(3) {
                @include mobile{
                    width: 10vw;
                }
            }
        }

        &::-webkit-scrollbar {
            width: 5px;

            &-thumb {
                background: var(--gdpr-cookies-group-scrollbar-thumb-color);
                border-radius: 2px;
            }

            &-track {
                background-color: var(--gdpr-cookies-group-scrollbar-track-color);
                box-shadow: none;
            }
        }
    }

    &-Row {
        @include mobile {
            display: table;
            table-layout: fixed;
            width: 100%;
        }
    }

    &-Done {
        width: 100%;

        @include mobile {
            margin: 14px 0;
        }
    }

    &-Heading {
        margin-bottom: 15px;
    }
}
