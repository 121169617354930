/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

/* stylelint-disable declaration-no-important */

:root {
    --icon-background-color: rgba(255, 255, 255, 0.2);
}

.HomepageBanner {
    .homepage-slider {
        h1 {
            color: #FFFFFF;
            text-transform: none;
            margin-block-end: 30px;

            @include mobile {
                font-size: 24px;
            }

            @include small-mobile {
                font-size: 20px;
            }
        }

        a {
            @include mobile {
                width: 177px;
                padding: 0;
                height: initial !important;
            }
        }
    }

    .homepage-slider .SliderWidget-Figcaption {
        padding-block-start: 0;
        z-index: 2;
    }

    .SliderWidget-Figure {
        height: 452px;
        position: relative;

        @include mobile {
            height: 288px;
        }

        @include small-mobile {
            height: 216px;
        }
    }

    .SliderWidget-Figure img {
        display: block;
        width: 100%;
    }

    .SliderWidget-Figure::before {
        content: '';
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        inset-block-end: 0;
        inset-inline-end: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.55);
    }

    .Slider-Arrow {
        inset-block-start: 210px;
        z-index: 3;

        @include mobile {
            display: none;
        }
    }

    .Slider-Arrow_isPrev {
        inset-inline-start: 52px;
    }

    .Slider-Arrow_isNext {
        inset-inline-end: 52px;
    }

    .Slider-ImageZoomIcon {
        display: none;
    }

    .SliderWidget-FigureImage {
        padding-block-end: 20px;
    }

    .Slider-Crumbs {
        margin-block-start: 26px !important;

        @include mobile {
            margin-block-start: 10px !important;
        }
    }
}

.CustomerReviews_isDesktop { /* customer reviews desktop */
    @include desktop {
        h1 {
            margin-block-end: 26px;
            text-transform: none;
            text-align: center;
        }

        td {
            padding-inline-start: 90px;
            padding-inline-end: 90px;
        }

        .pagebuilder-overlay {
            min-height: 300px !important;

            @include mobile {
                min-height: 300px !important;
            }

            @include small-mobile {
                min-height: 300px !important;
            }
        }

        .Slider-Arrow {
            inset-block-start: 190px;
        }

        .Slider-Arrow .ChevronIcon {
            stroke: var(--body-content-secondary-color);
        }

        .Slider-Arrow_isPrev {
            margin-inline-start: 0;

            @include mobile {
                display: inline-block;
            }
        }

        .Slider-Arrow_isNext {
            margin-inline-end: 0;

            @include mobile {
                display: inline-block;
            }
        }

        .Slider-Crumbs {
            margin-block-start: 26px !important;

            @include mobile {
                margin-block-start: 10px !important;
            }
        }
    }

    @include mobile {
        display: none !important;
    }
}

.CustomerReviews_isMobile { /* customer reviews mobile */
    @include desktop {
        display: none !important;
    }

    @include mobile {
        h1 {
            text-transform: none;
            text-align: center;
        }

        .pagebuilder-overlay {
            min-height: 300px !important;
        }

        .Slider-Arrow {
            inset-block-start: 190px;
        }

        .Slider-Arrow .ChevronIcon {
            stroke: var(--body-content-secondary-color);
        }

        .Slider-Arrow_isPrev {
            margin-inline-start: 0;
            display: inline-block;
        }

        .Slider-Arrow_isNext {
            margin-inline-end: 0;
            display: inline-block;
        }

        .Slider-Crumbs {
            display: none;
        }
    }
}

.Slider {
    &-Image {
        min-width: 12px;
        min-height: 12px;
    }

    &-Crumbs {
        position: static;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        padding-block: 10px 18px;
        padding-inline: 16px;
    }

    &-Crumb {
        background-color: var(--primary-hover-color);

        &_isActive {
            width: 12px;
            height: 12px;
            background-color: var(--primary-base-color);
        }
    }

    &-Arrow {
        width: 32px;
        height: 32px;
        background-color: var(--icon-background-color);

        .ChevronIcon {
            stroke: var(--color-white);
        }

        &_isPrev {
            inset-inline-start: 16px;

            @include mobile {
                display: none;
            }
        }

        &_isNext {
            inset-inline-end: 16px;

            @include mobile {
                display: none;
            }
        }
    }

    &-ImageZoomIcon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background-color: var(--icon-background-color);
        inset-inline-end: 16px;
        inset-block-end: 16px;
        cursor: pointer;

        @include mobile {
            display: none;
        }
    }
}
/* stylelint-enable declaration-no-important */
