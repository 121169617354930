/* stylelint-disable scss/at-import-partial-extension */
@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';
@import '../../../../../src/style/abstract/variables.override';
@import '../../../../../src/style/abstract/media.override';
@import '../../../../../src/style/abstract/button.override';

/*!
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

:root {
    --privacy-policy-button-width: auto;
    --privacy-policy-button-font-size: inherit;
    --privacy-policy-button-margin: 0;
}

.PrivacyPolicyButton {
    &.Button {
        width: var(--privacy-policy-button-width);
        font-size: var(--privacy-policy-button-font-size);
        margin: var(--privacy-policy-button-margin);
        background-color: unset;
        border: none;
        min-height: unset;
        min-width: unset;
        height: 100%;
        line-height: 1;
        color: var(--primary-base-color);
        font-weight: normal;
        text-transform: unset;
        padding: 0;

        &:not([disabled]) {
            &:hover,
            &:focus {
                background-color: unset;
                text-decoration: underline;
                color: var(--primary-base-color);
                padding: 0;
                height: 100%;
            }
        }
    }
}
