/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.ImageZoomPopup {
    &-PopupContent.Popup-Content {
        padding: 80px 56px;
        height: min-content;
        max-width: 1336px;

        .Popup-CloseBtn {
            inset-block-start: 32px;
            inset-inline-end: 34px;

            .CloseIcon {
                fill: var(--body-content-secondary-color);
            }
        }
    }

    .ProductGallery {
        display: flex;
        flex-direction: row-reverse;
    }

    .ProductGallery-Additional_isImageZoomPopupActive {
        position: static;
        height: min-content;
        border: none;
    }

    .ProductGallery-SliderWrapper {
        max-height: none;
        height: 545px;
        width: auto;
        margin-inline-start: 80px;
        margin-inline-end: 50px;

        .Slider-Arrow {
            display: block;
            height: 32px;
            width: 32px;
            background-color: var(--background-hover-color);

            .ChevronIcon {
                stroke: var(--body-content-secondary-color);
            }

            &_isDisabled {
                opacity: 1;
            }
        }

        .Slider-Arrow_isPrev {
            transform: translateX(-200%) translateY(-50%);
        }

        .Slider-Arrow_isNext {
            transform: translateX(200%) translateY(-50%);
        }

        .Slider-ImageZoomIcon {
            display: none;
        }
    }

    .ProductGallery-Slider {
        .Slider {
            &-Wrapper {
                height: 100%;
            }
        }
    }

    .CarouselScroll {
        width: min-content;
        margin: 0;

        &-Content {
            display: grid;
            grid-template-columns: 147px;
            grid-auto-rows: 92px;
            height: 545px;
            padding-inline-end: 8px;
            gap: 8px;
            justify-self: start;
            overflow-y: scroll;
            scroll-behavior: smooth;

            &::-webkit-scrollbar {
                width: 8px;

                &-track {
                    background-color: var(--background-stroke-color);
                }

                &-thumb {
                    background-color: var(--body-content-secondary-color);
                }
            }

            div {
                padding: 0;
            }
        }
    }

    .CarouselScroll-ContentWrapper {
        display: flex;
        flex-direction: column;
        width: min-content;
    }

    .CarouselScrollItem {
        width: 147px;
        height: 92px;
        border-image-width: 0;

        & + .CarouselScrollItem {
            margin-inline-start: 0;
        }

        &_isActive {
            border: 2px solid var(--primary-base-color);
        }
    }

    .CarouselScrollArrow {
        display: none;
    }
}
