/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

:root {
    --item-width: 118px;
    --item-height: 73px
}

.CarouselScrollItem {
    flex: unset;
    width: var(--item-width);
    height: var(--item-height);
    border-width: 0;
    border-color: var(--color-white);
    border-radius: 0;
    filter: opacity(1);

    &_isActive {
        border: 2px solid var(--primary-base-color);
    }
}
