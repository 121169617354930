.with-tooltip {
    display: flex;
    justify-content: center;
    position: relative;

    .tooltip {
        position: absolute;
        width: 15px;
        height: 15px;
        background-image: url('../../style/icons/ProductPage/icon_info.svg');
        background-size: contain;
        background-repeat: no-repeat;
        inset-block-start: -10px;
        inset-inline-end: -15px;

        &:hover {
            cursor: pointer;

            .message {
                display: block;
            }
        }

        .message {
            position: absolute;
            width: 200px;
            background: var(--background-white-color);
            padding: 5px 10px;
            border-radius: 10px;
            display: none;
            z-index: 1;

            @include mobile {
                inset-block-start: -45px;
                inset-inline-start: 0;
                transform: translate(-50%, -50%);
            }

            @include desktop {
                inset-block-start: 50%;
                inset-inline-start: calc(100% + 10px);
                transform: translateY(-50%);
            }
        }
    }
}
