.customer-reviews-slider {
    height: fit-content;
    max-width: 642px;
    padding: 32px;
    border: 1px solid var(--background-stroke-color);


    @include tablet {
        padding: 16px;
    }

    @include mobile {
        padding: 16px;
        margin-inline-start: auto;
        margin-inline-end: auto;
        margin-block-end: 0;
    }

    .customer-reviews-title {
        font-weight: 700;
        margin-block-end: 10px;

        @include mobile {
            /* stylelint-disable-next-line */
            text-align: center !important;
            margin-block-end: 2px;
        }
    }

    .pagebuilder-slide-wrapper .pagebuilder-overlay {
        /* stylelint-disable-next-line */
        padding: 40px 32px 0 32px !important;
    }

    .pagebuilder-slide-wrapper [data-element='content'] {
        padding: 0 32px;

        @media (max-width: 414px) {
            padding: 0 8px;
        }
    }

    .review-title {
        font-size: var(--h2-font-size);
        line-height: var(--h2-line-height);
        margin-block-end: 8px;

        @include mobile {
            font-size: var(--h2-font-size-mobile);
            line-height: var(--h2-line-height-mobile);
        }
    }

    .review-text {
        margin-block-end: 8px;
    }

    .review-author {
        margin-block-end: 0;
    }

    .Slider-Arrow_isPrev {
        background: url(../../style/icons/ServicePage/arrow-left.svg) no-repeat;
        background-position-x: center;

        @media (max-width: 414px) {
            background: none;
            display: inline-block;
        }
    }

    .Slider-Arrow_isNext {
        background: url(../../style/icons/ServicePage/arrow-right.svg) no-repeat;
        background-position-x: center;

        @media (max-width: 414px) {
            background: none;
            display: inline-block;
        }
    }

    .SliderArrow.ChevronIcon {
        display: none;

        @media (max-width: 414px) {
            display: inline-block;
            stroke: var(--body-content-secondary-color);
        }
    }

    .Slider-Crumbs {
        @include mobile {
            display: none;
        }
    }
}
