/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.StoreSwitcher {
    .FieldSelect-Select,
    &-Title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--body-content-color);
    }

    .FieldSelect-Clickable,
    &-Title {
        svg.ChevronIcon {
            width: 16px;
            height: 16px;
            margin-block-start: 3px;
            fill: var(--body-content-color);
        }
    }

    &-Title {
        img {
            height: 24px;
            width: 24px;
            margin-inline-end: 4px;
        }
    }

    &-StoreList {
        inset-inline-end: -24px;

        &_isOpen {
            border-color: var(--primary-base-color);
        }
    }

    .StoreItem-Item {
        font-size: 16px;
        text-align: start;
        cursor: pointer;
        color: var(--body-content-secondary-color);

        &:hover {
            @include desktop {
                background-color: var(--primary-base-color);
                color: var(--color-white);
            }
        }
    }
}
