/* stylelint-disable scss/at-import-partial-extension */
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/variables.override';
@import '../../style/abstract/media.override';
@import '../../style/abstract/button.override';

.FieldSelect {
    &-Clickable {
        font-size: 16px;
        line-height: 24px;
    }

    &-Select {
        height: 52px;
        color: var(--body-content-secondary-color);

        & + svg {
            fill: var(--body-content-secondary-color);
        }

        &:disabled + svg {
            fill: var(--body-content-disabled-color);
        }

        &_isSelected {
            color: var(--body-content-color);

            & + svg {
                fill: var(--color-black);
            }
        }
    }

    &-Options {
        @include mobile {
            display: none;
        }

        &_isExpanded {
            max-height: 145px;

            @include desktop {
                border-block-start: none;
            }
        }

        .FieldSelectOptions {
            &-Wrapper {
                max-height: 145px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;

                    &-track {
                        background-color: var(--background-stroke-color);
                    }

                    &-thumb {
                        background-color: var(--body-content-secondary-color);
                    }
                }
            }
        }
    }

    &-Option {
        font-size: 16px;
        color: var(--body-content-secondary-color);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &_isPlaceholder {
            opacity: 1;
        }

        &:hover,
        &:focus,
        &_isHovered {
            @include desktop {
                background: var(--primary-base-color);
                color: var(--color-white);
            }
        }
    }
}
